import { matchPath, useLocation } from 'react-router-dom'

import { AnalysisPage } from 'components/common/categorizationModal/AnalysisPage'
import { DetailsPage } from 'components/common/categorizationModal/DetailsPage'
import { LandingPage } from 'components/common/categorizationModal/LandingPage'
import { routesManager } from 'utils/routesManager'

export const CategorizationSideModal = () => {
  const { pathname } = useLocation()
  return (
    <>
      {matchPath(routesManager.evaluatorTools.landing.pattern, pathname) && <LandingPage />}
      {matchPath(routesManager.evaluatorTools.categorisation.pattern, pathname) && <LandingPage />}
      {matchPath(routesManager.evaluatorTools.analysis.pattern, pathname) && <AnalysisPage />}
      {matchPath(routesManager.evaluatorTools.details.pattern, pathname) && <DetailsPage />}
    </>
  )
}
