import endToEnd1 from 'assets/end-to-end-1.png'
import endToEnd2 from 'assets/end-to-end-2.png'
import endToEnd3 from 'assets/end-to-end-3.png'
import endToEnd4 from 'assets/end-to-end-4.png'
import endToEnd5 from 'assets/end-to-end-5.png'
import { routesManager } from 'utils/routesManager'

export type Tag = 'R' | 'A' | 'I' | 'C'

export interface Step {
  id: number
  name: string
  subtitle: string
  description: string
  cardColor: string
  team: { text: string; tags: Tag[] }[]
  buttons?: { name: string; route: string; variant: 'primary' | 'secondary' }[]
  color: string
  image: string
  stepsBefore: number[]
  stepsAfter: number[]
}

export const steps: Step[] = [
  {
    id: 1,
    name: 'Step 1',
    subtitle: 'Prepare and Submit your Elevator Pitch',
    description: 'For CTO Product Team to assess funding potential and to reveal the type of support to be provided.',
    cardColor:
      'linear-gradient(90deg, var(--wpp-dataviz-color-seq-positive-400) 0%, var(--wpp-dataviz-color-cat-neutral-5) 59.72%)',
    team: [
      {
        text: 'Agency Product Team',
        tags: ['R', 'A'],
      },
      {
        text: 'CTO Product Team',
        tags: ['I'],
      },
    ],
    buttons: [
      {
        name: 'LivePlan',
        route: routesManager.liveplan.root.getURL(),
        variant: 'primary',
      },
    ],
    color: 'var(--wpp-dataviz-color-seq-positive-400)',
    image: endToEnd1,
    stepsBefore: [],
    stepsAfter: [2, 3],
  },
  {
    id: 2,
    name: 'Step 2',
    subtitle: 'Start Pre-Board Preparation',
    description:
      'Assess funding opportunities and prepare for take-off with a solid business plan in pre-board preparation.',
    cardColor:
      'linear-gradient(90deg, var(--wpp-dataviz-color-cat-neutral-8) 0%, var(--wpp-dataviz-color-seq-positive-400) 59.72%)',
    team: [
      {
        text: 'Agency Product Team',
        tags: ['R', 'A'],
      },
      {
        text: 'CTO Product Team',
        tags: ['R'],
      },
    ],
    buttons: [
      {
        name: 'LivePlan',
        route: routesManager.liveplan.root.getURL(),
        variant: 'primary',
      },
      {
        name: 'Benefits Matrix',
        route: routesManager.evaluatorTools.benefits.getURL(),
        variant: 'secondary',
      },
    ],
    color: 'var(--wpp-dataviz-color-cat-neutral-8)',
    image: endToEnd2,
    stepsBefore: [1],
    stepsAfter: [3],
  },
  {
    id: 3,
    name: 'Step 3',
    subtitle: 'Pre-Board Presentation',
    description:
      'Get ready to shine with a polished pre-board presentation, completed by your team, with the support of the CTO Product Team',
    cardColor:
      'linear-gradient(90deg, var(--wpp-dataviz-color-seq-brand-500) 0%, var(--wpp-dataviz-color-cat-neutral-8) 59.72%)',
    team: [
      {
        text: 'Agency Product Team',
        tags: ['R'],
      },
      {
        text: 'CTO Product Team',
        tags: ['R'],
      },
      {
        text: 'Pre-Product Board',
        tags: ['A'],
      },
    ],
    buttons: undefined,
    color: 'var(--wpp-dataviz-color-seq-brand-600)',
    image: endToEnd3,
    stepsBefore: [2],
    stepsAfter: [4],
  },
  {
    id: 4,
    name: 'Step 4',
    subtitle: 'Present Business plan and Financials to Product Board',
    description:
      "It's time to showcase your team's hard work and present to the Product Board with the support of the CTO Product Team.",
    cardColor:
      'linear-gradient(90deg, var(--wpp-dataviz-color-cat-neutral-2) 0%, var(--wpp-dataviz-color-seq-brand-500) 59.72%)',
    team: [
      {
        text: 'Agency Product Team',
        tags: ['R'],
      },
      {
        text: 'CTO Product Team',
        tags: ['C'],
      },
      {
        text: 'Project Team',
        tags: ['A'],
      },
      {
        text: 'Product Board',
        tags: ['I'],
      },
    ],
    buttons: [
      {
        name: 'LivePlan',
        route: routesManager.liveplan.root.getURL(),
        variant: 'primary',
      },
      {
        name: 'Categorisation Matrix',
        route: routesManager.evaluatorTools.categorisation.getURL(),
        variant: 'secondary',
      },
    ],
    color: 'var(--wpp-dataviz-color-cat-neutral-2)',
    image: endToEnd4,
    stepsBefore: [3],
    stepsAfter: [5],
  },
  {
    id: 5,
    name: 'Step 5',
    subtitle: 'Start of Funding and Reporting',
    description:
      'To ensure funding keeps flowing and that your product stays on track, regular reporting to the CTO Product Team will be required.',
    cardColor:
      'linear-gradient(90deg, var(--wpp-dataviz-color-seq-warning-500) 0%, var(--wpp-dataviz-color-cat-neutral-2) 59.72%)',
    team: [
      {
        text: 'CTO Product Team',
        tags: ['R', 'A'],
      },
    ],
    buttons: [
      {
        name: 'LivePlan',
        route: routesManager.liveplan.root.getURL(),
        variant: 'primary',
      },
    ],
    color: 'var(--wpp-dataviz-color-seq-warning-500)',
    image: endToEnd5,
    stepsBefore: [4],
    stepsAfter: [],
  },
]
