// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JEgta{padding-top:20px;padding-bottom:60px;word-break:break-word}.KECJV{width:auto;overflow:hidden;cursor:pointer}.A9pdp{width:100%;height:100%}", "",{"version":3,"sources":["webpack://./src/components/common/featuresSideModal/FeaturesSideModal.module.scss"],"names":[],"mappings":"AAAA,OACE,gBAAA,CACA,mBAAA,CACA,qBAAA,CAGF,OACE,UAAA,CACA,eAAA,CACA,cAAA,CAGF,OACE,UAAA,CACA,WAAA","sourcesContent":[".content {\n  padding-top: 20px;\n  padding-bottom: 60px;\n  word-break: break-word;\n}\n\n.imgWrapper {\n  width: auto;\n  overflow: hidden;\n  cursor: pointer;\n}\n\n.img {\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "JEgta",
	"imgWrapper": "KECJV",
	"img": "A9pdp"
};
export default ___CSS_LOADER_EXPORT___;
