import { WppButton, WppIconCross, WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'

import { Step, Tag } from 'components/common/endToEndSideModal/data'
import styles from 'components/common/endToEndSideModal/EndToEndSideModal.module.scss'
import { Flex } from 'components/common/flex/Flex'

export const mapTeamTags = (tag: Tag) => {
  let color = styles.whiteTag
  let tooltip = styles.whiteTooltip
  let text = ''
  switch (tag) {
    case 'R':
      color = styles.yellowTag
      tooltip = styles.yellowTooltip
      text = 'Responsible'
      break
    case 'A':
      color = styles.redTag
      tooltip = styles.redTooltip
      text = 'Accountable'
      break
    case 'C':
      text = 'Consulted'
      break
    case 'I':
      text = 'Informed'
      break
  }
  return (
    <div key={tag} className={styles.tagWithTooltip}>
      <div className={clsx(styles.tooltip, tooltip)}>
        <WppTypography type="s-body" tag="span" className={color === styles.whiteTag ? undefined : styles.white}>
          {text}
        </WppTypography>
      </div>
      <div className={clsx(styles.teamTag, color)}>
        <WppTypography type="2xs-strong" tag="span" className={styles.padding1Left}>
          {tag}
        </WppTypography>
      </div>
    </div>
  )
}

export interface Props {
  step: Step
  handleStepClick: (id: number) => void
}

export const BigBox = ({ step, handleStepClick }: Props) => {
  const navigate = useNavigate()

  return (
    <Flex justify="between" className={styles.bigStep}>
      <div className={styles.stepContent}>
        <Flex justify="between">
          <WppTypography type="2xl-heading" tag="h2" className={clsx(styles.white, styles.padding15Top)}>
            {step.name}
          </WppTypography>
          <div className={clsx(styles.stepCircle, styles.border)} onClick={() => handleStepClick(0)}>
            <WppIconCross color="var(--wpp-grey-color-000)" />
          </div>
        </Flex>
        <WppTypography type="l-strong" tag="span" className={styles.white}>
          {step.subtitle}
        </WppTypography>
        <WppTypography type="xs-midi" tag="span" className={styles.white}>
          {step.description}
        </WppTypography>
        {step.buttons && (
          <div className={styles.actionBtns}>
            {step.buttons.map(button => (
              <WppButton
                size="s"
                key={button.name}
                variant={button.variant}
                onClick={() => navigate(button.route)}
                className={button.name !== 'LivePlan' ? styles.whiteButton : undefined}
              >
                {button.name}
              </WppButton>
            ))}
          </div>
        )}
      </div>
      <div className={styles.step} style={{ background: step.cardColor, justifyContent: 'flex-start', border: 'none' }}>
        <div>
          <img src={step.image} alt={step.name} width="100%" height="100%" />
        </div>
        <div className={styles.team}>
          {step.team.map(team => (
            <Flex justify="between" key={team.text}>
              <WppTypography type="xs-strong" tag="span" className={styles.white}>
                {team.text}
              </WppTypography>
              <Flex gap={5}>
                {team.tags.map(tag => {
                  return mapTeamTags(tag)
                })}
              </Flex>
            </Flex>
          ))}
        </div>
      </div>
    </Flex>
  )
}
