// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".J9MsN{width:100%;margin-bottom:10px}.ACSN9{display:grid}.Xxs8G{margin-bottom:7px}", "",{"version":3,"sources":["webpack://./src/components/common/dropdownToolbar/DropdownToolbar.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CACA,kBAAA,CAGF,OACE,YAAA,CAGF,OACE,iBAAA","sourcesContent":[".expandableCard {\n  width: 100%;\n  margin-bottom: 10px;\n}\n\n.buttons {\n  display: grid;\n}\n\n.margin7 {\n  margin-bottom: 7px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"expandableCard": "J9MsN",
	"buttons": "ACSN9",
	"margin7": "Xxs8G"
};
export default ___CSS_LOADER_EXPORT___;
