import { WppCard, WppTypography } from '@platform-ui-kit/components-library-react'
import { MouseEvent } from 'react'

import styles from 'components/common/smallStepCard/SmallStepCard.module.scss'

export interface Props {
  stepNumber?: number
  title: string
  subtitle?: string
  image: string
  color?: string
  description?: string
  handleClick: (event: MouseEvent, cardNumber?: number) => void
}

export const SmallStepCard = ({ stepNumber, title, subtitle, image, description, color, handleClick }: Props) => (
  <WppCard className={styles.card} onClick={e => handleClick(e, stepNumber)} interactive>
    <div style={{ background: color ? color : undefined }} className={styles.imgWrapper}>
      <div className={color ? styles.imageDiv80 : styles.imageDiv}>
        <img src={require(`assets/${image}.png`)} alt={title} />
      </div>
    </div>
    <div className={styles.content}>
      <WppTypography
        className={styles.cardTitle}
        type="l-strong"
        tag="h4"
        style={{
          background: color ? color : undefined,
          WebkitBackgroundClip: color ? 'text' : undefined,
          WebkitTextFillColor: color ? 'transparent' : undefined,
        }}
      >
        {title}
      </WppTypography>
      {subtitle && (
        <WppTypography className={styles.cardTitle} type="m-strong" tag="h4">
          {subtitle}
        </WppTypography>
      )}
      {description && (
        <WppTypography className={styles.description} type="xs-body" tag="span">
          {description}
        </WppTypography>
      )}
    </div>
  </WppCard>
)
