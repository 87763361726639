import { WppSideModal, WppTypography } from '@platform-ui-kit/components-library-react'
import { ReactElement } from 'react'

import styles from 'components/common/featuresSideModal/FeaturesSideModal.module.scss'

export interface Props {
  title: string
  content: ReactElement
  image: string
  isOpen: boolean
  handleCloseModal: () => void
  handleOpenModal: () => void
}

export const FeaturesSideModal = ({ title, content, image, isOpen, handleCloseModal, handleOpenModal }: Props) => (
  <WppSideModal open={isOpen} onWppSideModalClose={handleCloseModal} onWppSideModalOpen={handleOpenModal} size="l">
    <WppTypography type="2xl-heading" tag="h3" slot="header">
      {title}
    </WppTypography>
    <div slot="body">
      <div className={styles.content}>{content}</div>
      <div className={styles.imgWrapper}>
        <img src={require(`assets/${image}.png`)} alt={title} className={styles.img} />
      </div>
    </div>
  </WppSideModal>
)
