// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iBjMh{display:block;margin-bottom:30px}.bEZBW{margin-bottom:40px}.kjHn5{margin-bottom:20px}.VrqLH{width:100%}.GirZO{width:calc(50% - 10px)}.SL91f{--page-left-right-padding: 38px;--page-top-bottom-padding: 54px;padding:var(--page-top-bottom-padding) var(--page-left-right-padding)}.UyQsh{width:50%}.J5Tx3{margin-bottom:32px}", "",{"version":3,"sources":["webpack://./src/pages/contact/ContactPage.module.scss"],"names":[],"mappings":"AAAA,OACE,aAAA,CACA,kBAAA,CAGF,OACE,kBAAA,CAGF,OACE,kBAAA,CAGF,OACE,UAAA,CAGF,OACE,sBAAA,CAGF,OACE,+BAAA,CACA,+BAAA,CAEA,qEAAA,CAGF,OACE,SAAA,CAGF,OACE,kBAAA","sourcesContent":[".title {\n  display: block;\n  margin-bottom: 30px;\n}\n\n.marginBottom40 {\n  margin-bottom: 40px;\n}\n\n.marginBottom20 {\n  margin-bottom: 20px;\n}\n\n.width100 {\n  width: 100%;\n}\n\n.width50 {\n  width: calc(50% - 10px);\n}\n\n.pageContent {\n  --page-left-right-padding: 38px;\n  --page-top-bottom-padding: 54px;\n\n  padding: var(--page-top-bottom-padding) var(--page-left-right-padding);\n}\n\n.form {\n  width: 50%;\n}\n\n.contactCard {\n  margin-bottom: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "iBjMh",
	"marginBottom40": "bEZBW",
	"marginBottom20": "kjHn5",
	"width100": "VrqLH",
	"width50": "GirZO",
	"pageContent": "SL91f",
	"form": "UyQsh",
	"contactCard": "J5Tx3"
};
export default ___CSS_LOADER_EXPORT___;
