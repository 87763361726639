import { WppTypography } from '@platform-ui-kit/components-library-react'

import { ImageFullscreenModal } from 'components/common/imageFullscreenModal/ImageFullscreenModal'
import { routesManager } from 'utils/routesManager'

import styles from './Data.module.scss'

export const data = {
  steps: [
    {
      id: 1,
      cardTitle: 'Step 1',
      cardSubtitle: 'Elevator Pitch',
      cardDescription:
        "Your elevator pitch is the first step on the road to realising your product's potential. It will be evaluated by members of the Funding Team.",
      cardImage: 'step1-big',
      cardColor:
        'linear-gradient(90deg, var(--wpp-dataviz-color-seq-positive-400) 0%, var(--wpp-dataviz-color-cat-neutral-5) 59.72%)',
      title: "What's involved in the elevator pitch?",
    },
    {
      id: 2,
      cardTitle: 'Step 2',
      cardSubtitle: 'Pre-Board Preparation',
      cardDescription:
        "Step 1 is done! Now it's time to leap to step 2. Assess funding opportunities and prepare for take off with a solid business plan in pre-board preparation.",
      cardImage: 'step2-big',
      cardColor:
        'linear-gradient(90deg, var(--wpp-dataviz-color-cat-neutral-8) 0%, var(--wpp-dataviz-color-seq-positive-400) 59.72%)',
      title: "What's involved in the pre-board preparation?",
    },
    {
      id: 3,
      cardTitle: 'Step 3',
      cardSubtitle: 'Pre-Board Presentation',
      cardDescription:
        'Step 2 complete! Get ready to shine with a polished pre-board presentation in step 3. Your team, supported by the CTO Product Team, will present to the Product Board.',
      cardImage: 'step3-big',
      cardColor:
        'linear-gradient(90deg, var(--wpp-dataviz-color-seq-brand-500) 0%, var(--wpp-dataviz-color-cat-neutral-8) 59.72%)',
      title: "What's involved in the pre-board presentation?",
    },
    {
      id: 4,
      cardTitle: 'Step 4',
      cardSubtitle: 'Product Board',
      cardDescription:
        "Step 3 check! In step 4, it's time to showcase your team's hard work and present to the Product Board with the support of the CTO Product Team.",
      cardImage: 'step4-big',
      cardColor:
        'linear-gradient(90deg, var(--wpp-dataviz-color-cat-neutral-2) 0%, var(--wpp-dataviz-color-seq-brand-500) 59.72%)',
      title: "What's involved in the Product Board?",
    },
    {
      id: 5,
      cardTitle: 'Step 5',
      cardSubtitle: 'Funding and Reporting',
      cardDescription:
        'Congratulations! You have now received funding for the product! To ensure funding keeps flowing and that your product stays on track, regular reporting to the CTO Product Team will be required.',
      cardImage: 'step5-big',
      cardColor:
        'linear-gradient(90deg, var(--wpp-dataviz-color-seq-warning-500) 0%, var(--wpp-dataviz-color-cat-neutral-2) 59.72%)',
      title: 'What does funding and reporting involve?',
    },
  ],
}

export const stepRightNavigation = [
  [
    {
      title: 'Team',
      value: (
        <>
          <WppTypography type="s-strong" className={styles.teamText}>
            Agency/OpCo:&nbsp;
          </WppTypography>
          <WppTypography type="s-body">Product Team</WppTypography>
          <br />
          <WppTypography type="s-strong" className={styles.teamText}>
            WPP Global:&nbsp;
          </WppTypography>
          <WppTypography type="s-body">CTO Product Team</WppTypography>
        </>
      ),
      opened: false,
    },
    {
      title: 'Tools',
      buttons: [
        {
          name: 'LivePlan',
          route: routesManager.liveplan.root.getURL(),
        },
        {
          name: 'Benefits Matrix',
          route: routesManager.evaluatorTools.benefits.getURL(),
        },
      ],
      opened: true,
    },
    {
      title: 'Timeframe',
      value: <WppTypography type="s-body">At your own pace</WppTypography>,
      opened: false,
    },
    {
      title: 'Elevator pitch flow',
      value: <ImageFullscreenModal img="step1-flow" title="Step 1: Prepare and Submit Elevator Pitch" />,
      opened: false,
    },
  ],
  [
    {
      title: 'Team',
      value: (
        <>
          <WppTypography type="s-strong" className={styles.teamText}>
            Agency/OpCo:&nbsp;
          </WppTypography>
          <WppTypography type="s-body">Product Team</WppTypography>
          <br />
          <WppTypography type="s-strong" className={styles.teamText}>
            WPP Global:&nbsp;
          </WppTypography>
          <WppTypography type="s-body">CTO Product Team</WppTypography>
        </>
      ),
      opened: false,
    },
    {
      title: 'Tools',
      buttons: [
        {
          name: 'LivePlan',
          route: routesManager.liveplan.root.getURL(),
        },
        {
          name: 'Benefits Matrix',
          route: routesManager.evaluatorTools.benefits.getURL(),
        },
      ],
      opened: true,
    },
    {
      title: 'Timeframe',
      value: <WppTypography type="s-body">Evaluators review business plans on a monthly basis.</WppTypography>,
      opened: false,
    },
    {
      title: 'Pre-Board preparation flow',
      value: <ImageFullscreenModal img="step2-flow" title="Step 2: Pre-Board Preparation" />,
      opened: false,
    },
  ],
  [
    {
      title: 'Team',
      value: (
        <>
          <WppTypography type="s-strong" className={styles.teamText}>
            Agency/OpCo:&nbsp;
          </WppTypography>
          <WppTypography type="s-body">Product Team</WppTypography>
          <br />
          <WppTypography type="s-strong" className={styles.teamText}>
            WPP Global:&nbsp;
          </WppTypography>
          <WppTypography type="s-body">CTO Product Team</WppTypography>
          <WppTypography type="s-body">Pre-Product Board</WppTypography>
        </>
      ),
      opened: false,
    },
    {
      title: 'Tools',
      buttons: [
        {
          name: 'LivePlan',
          route: routesManager.liveplan.root.getURL(),
        },
      ],
      opened: true,
    },
    {
      title: 'Timeframe',
      value: (
        <WppTypography type="s-body">
          30 minutes presentation overall, 10 minutes for Q&A. The pre-board review is done on a monthly basis.
        </WppTypography>
      ),
      opened: false,
    },
    {
      title: 'Pre-Board presentation flow',
      value: <ImageFullscreenModal img="step3-flow" title="Step 3: Pre-Board Presentation" />,
      opened: false,
    },
  ],
  [
    {
      title: 'Team',
      value: (
        <>
          <WppTypography type="s-strong" className={styles.teamText}>
            Agency/OpCo:&nbsp;
          </WppTypography>
          <WppTypography type="s-body">Product Team</WppTypography>
          <WppTypography type="s-body">Project Team</WppTypography>
          <br />
          <WppTypography type="s-strong" className={styles.teamText}>
            WPP Global:&nbsp;
          </WppTypography>
          <WppTypography type="s-body">CTO Product Team</WppTypography>
          <WppTypography type="s-body">Product Board</WppTypography>
        </>
      ),
      opened: false,
    },
    {
      title: 'Tools',
      buttons: [
        {
          name: 'LivePlan',
          route: routesManager.liveplan.root.getURL(),
        },
        {
          name: 'Categorisation Matrix',
          route: routesManager.evaluatorTools.categorisation.getURL(),
        },
      ],
      opened: true,
    },
    {
      title: 'Timeframe',
      value: <WppTypography type="s-body">The Product Board meets quarterly.</WppTypography>,
      opened: false,
    },
  ],
  [
    {
      title: 'Team',
      value: (
        <>
          <WppTypography type="s-strong" className={styles.teamText}>
            Agency/OpCo:&nbsp;
          </WppTypography>
          <WppTypography type="s-body">Product Team</WppTypography>
          <br />
          <WppTypography type="s-strong" className={styles.teamText}>
            WPP Global:&nbsp;
          </WppTypography>
          <WppTypography type="s-body">CTO Product Team</WppTypography>
        </>
      ),
      opened: false,
    },
    {
      title: 'Tools',
      buttons: [
        {
          name: 'LivePlan',
          route: routesManager.liveplan.root.getURL(),
        },
        {
          name: 'Categorisation Matrix',
          route: routesManager.evaluatorTools.categorisation.getURL(),
        },
      ],
      opened: true,
    },
    {
      title: 'Timeframe',
      value: <WppTypography type="s-body">You'll have monthly support from nthe evaluating team member.</WppTypography>,
      opened: false,
    },
    {
      title: 'Funding and reporting flow',
      value: <ImageFullscreenModal img="step5-flow" title="Step 5: Funding and Reporting" />,
      opened: false,
    },
  ],
]

export const stepTabs = [
  [
    {
      title: 'Prepare a summary',
      value: (
        <>
          <WppTypography type="s-body">
            Start by creating a summary of the new product or opportunity, highlighting the key features and benefits it
            offers. This summary should be a clear and concise overview of the opportunity that can be used to spark
            interest and generate further discussion.
            <br />
            <br />
            As soon as the summary is prepared and your idea is clear, please contact your CTO Product Team via e-mail
            and share it with them. Afterwards, please go to the Funding Process Page in WPP Open OS and request access
            to LivePlan via the Intercom widget.
            <br />
            <br />
            LivePlan tool is business planning software that can help to outline key elements of the pitch such as:
            <br />
            - market research
            <br />
            - demand and competition analysis
            <br />
            - proposed solution and budget.
            <br />
          </WppTypography>
        </>
      ),
    },
    {
      title: 'Create a pitch one-pager',
      value: (
        <>
          <WppTypography type="s-body">
            Aim: to assess funding potential and to reveal the type of support to be provided.
            <br />
            <br />
            In LivePlan you need to create a pitch one-pager that describes the new product or opportunity in more
            detail:
            <br />
            <br />
            - describe a client challenge and the solution that your product or opportunity offers
            <br />
            - include market research-demand and competitors' analysis
            <br />
            - describe advantages of your product or opportunity
            <br />
            - specify the required budget.
            <br />
          </WppTypography>
        </>
      ),
    },
    {
      title: 'Submit a pitch one-pager',
      value: (
        <>
          <WppTypography type="s-body">
            Submit the link to your pitch one-pager document in LivePlan to the CTO Product Team for review via Support
            widget (by clicking the relevant option) or via email. This should be clearly written and well-organized,
            making it easy for stakeholders to understand the key elements of the pitch and the potential benefits to
            the organisation.
            <br />
            <br />
            Assessment is done based on:
            <br />
            - the benefits to WPP as a whole
            <br />
            - the benefits to Product team OpCo
            <br />
            - the scale of return
            <br />
            - the scale of build.
            <br />
            <br />
          </WppTypography>
        </>
      ),
    },
    {
      title: 'Evaluators review pitch',
      value: (
        <>
          <WppTypography type="s-body">
            Evaluating stakeholders (CTO Product Team) will review the pitch using the Benefits Matrix, evaluating the
            potential benefits to the organisation, as well as the potential return on investment and scale to build.
          </WppTypography>
        </>
      ),
    },
    {
      title: 'Outcome',
      value: (
        <>
          <WppTypography type="s-body">
            There are three possible outcomes of the review:
            <br />
            <br />
            - a green light to go-ahead
            <br />
            - the idea is rejected, or
            <br />
            - the product idea needs to be evolved.
            <br />
            <br />
            If the product idea needs to be evolved, you will receive feedback with comments relating to the areas that
            should be revised and updated. As soon as you are ready and your pitch one-pager is updated, you will need
            to submit this again. The CTO Product Team will evaluate the changes that you have made. This process of
            feedback and evaluation will continue until all stakeholders are satisfied with the proposal and are ready
            to give it the green light.
          </WppTypography>
        </>
      ),
    },
  ],
  [
    {
      title: 'Create a business plan',
      value: (
        <>
          <WppTypography type="s-body">
            Creating a fully baked business plan within LivePlan involves several steps:
            <br />
            <br />
            - identify the opportunity, both from internal and external perspectives. This includes identifying the
            market need, target customer, and competition
            <br />
            - specify the execution plan, including details on marketing, operations, and milestones
            <br />
            - provide an overview of the company and the team members involved
            <br />
            - include financial forecasting and a projected profit and loss statement.
            <br />
            <br />
            It's time to submit your business plan for review via Support widget (by clicking the relevant option) or
            via email.
          </WppTypography>
        </>
      ),
    },
    {
      title: 'Evaluators review business plan',
      value: (
        <>
          <WppTypography type="s-body">
            The CTO Product Team will use a Benefits Matrix evaluation tool to categorise and score your plan. After the
            review, a member of the product team will be assigned to you to provide support throughout the process.
            Evaluators review business plans on a monthly basis.
          </WppTypography>
        </>
      ),
    },
    {
      title: 'Review the feedback',
      value: (
        <>
          <WppTypography type="s-body">
            As soon as you receive feedback from the CTO Product Team, review their comments and make sure to integrate
            these into your plan.
          </WppTypography>
        </>
      ),
    },
    {
      title: 'Plan your presentation',
      value: (
        <>
          <WppTypography type="s-body">
            Once the plan is finalised, it is ready for pre-board review, which is a formal presentation to the Product
            Board for approval.
          </WppTypography>
        </>
      ),
    },
    {
      title: 'Outcome',
      value: (
        <>
          <WppTypography type="s-body">
            We will work closely with you until your business plan is fully ready to receive green light to proceed
            further. You will also receive dedicated support from CTO Product Team member and will be notified about
            estimated timeliness for your pre-board presentation.
          </WppTypography>
        </>
      ),
    },
  ],
  [
    {
      title: 'Plan the presentation',
      value: (
        <>
          <WppTypography type="s-body">
            Add financials into LivePlan:
            <br />
            <br />
            - insert total funding required
            <br />
            - fill in the terms and schedule of disbursement.
            <br />
            <br />
            It's time to bring in the Project Team and involve the Subject-Matter Experts (SMEs). While you are doing
            this, the CTO Product Team will collate the presentation of the business plan.
          </WppTypography>
        </>
      ),
    },
    {
      title: 'Present the business plan',
      value: (
        <>
          <WppTypography type="s-body">
            Present the business plan, which has been collated and prepared in LivePlan, to the CTO Product Team. The
            CTO Product Team will listen to the presentation and ask questions to understand the plan in more detail.
            Timeframe: 30 minutes presentation overall, 10 minutes for Q&A. The pre-board review takes place on a
            monthly basis.
          </WppTypography>
        </>
      ),
    },
    {
      title: 'CTO Product Team review the business plan',
      value: (
        <>
          <WppTypography type="s-body">
            After the presentation, the CTO Product Team will review the business plan and decide on next steps. There
            are two possible outcomes:
            <br />
            <br />
            - the product doesn't meet the requirement, it must be reviewed and updated by the product team
            <br />
            - the product has met the necessary requirements, the CTO Product Team share the inputs on planned total
            funding, conditions and terms/schedule of disbursement. These will be provided two weeks after the pre-board
            approval. Product Board is then planned.
            <br />
            <br />
            The pre-board review takes place on a monthly basis.
          </WppTypography>
        </>
      ),
    },
  ],
  [
    {
      title: 'Congratulations',
      value: (
        <>
          <WppTypography type="s-body">
            You have successfully presented your business plan and financials to the Product Board and have received
            approval for funding.
            <br />
            The board has given the green light, a Go decision was made, and the funding will be provided.
          </WppTypography>
        </>
      ),
    },
    {
      title: 'How evaluators assess the plan',
      value: (
        <>
          <WppTypography type="s-body">
            The evaluating Product Board uses the Categorisation Matrix to assess the plan and has the authority to make
            the final decision on whether to proceed with the product or opportunity. They may also provide feedback on
            the plan and financials. The Product Board meets on a quarterly basis.
          </WppTypography>
        </>
      ),
    },
  ],
  [
    {
      title: 'Keep LivePlan Tracker up to date',
      value: (
        <>
          <WppTypography type="s-body">
            Keep the LivePlan tool tracker up to date - this will help us stay on top of our progress and make sure
            we're meeting our goals.
          </WppTypography>
        </>
      ),
    },
    {
      title: 'Regular catch-ups',
      value: (
        <>
          <WppTypography type="s-body">
            Make sure you have regular catch-ups with your assigned CTO Product Team member - this will give you the
            opportunity to share updates, ask questions, and receive feedback on your progress.
          </WppTypography>
        </>
      ),
    },
    {
      title: 'Complete your Term Sheets',
      value: (
        <>
          <WppTypography type="s-body">
            Continue to work on filling out the term sheet elements and keeping these up to date. This will help to
            establish a clear plan for the product and ensure that you stay on track.
          </WppTypography>
        </>
      ),
    },
    {
      title: 'Ongoing Support',
      value: (
        <>
          <WppTypography type="s-body">
            Every month you'll have the support of the CTO Product Team to help with the product categorisation matrix
            and term sheet elements. They will also support you through:
            <br />
            <br />
            - build - working as a build partner
            <br />
            - pilots
            <br />
            - POCs
            <br />
            - client adoption
            <br />
            - marketing and branding.
            <br />
            <br />
            With their guidance, we'll be able to take this product to the next level!
            <br />
            <br />
            Funding is flowing, and the product is on track!
          </WppTypography>
        </>
      ),
    },
  ],
]

export const features = [
  {
    id: '1',
    title: 'Pitch',
    content: (
      <WppTypography type="s-body">
        The LivePlan pitch focuses on documenting the core problems a business is setting out to solve.
        <br />
        It’s critical for any business to have a laser focus on the problem they are solving, rather than maintaining
        focus on the solution.
        <br />
        If you have a deep understanding of your customer and their needs, the best actions will naturally follow.
        <br />
        Tip: This “Lean Plan” works for any business, at any stage of growth, to define and meet your goals
      </WppTypography>
    ),
    image: 'pitch',
  },
  {
    id: '2',
    title: 'Plan',
    content: (
      <WppTypography type="s-body">
        The business planning process is made easy with prompts, definitions of key terms, and video and text tutorials
        built right into LivePlan.
        <br />
        Your plan will be set up with a standard recommended outline of chapters, sections, and topics (tables, charts,
        etc.).
        <br />
        You can either begin writing to that outline, or modify it as you want by rearranging, adding, or removing parts
        of the outline.
      </WppTypography>
    ),
    image: 'plan',
  },
  {
    id: '3',
    title: 'Forecast',
    content: (
      <WppTypography type="s-body">
        LivePlan automatically creates all the financial forecast tables you need to manage your business.
        <br />
        You don't have to worry about navigating complex spreadsheets. LivePlan takes you step-by-step through the
        process of creating the powerful financial reports you need for your business plan—even if you don't have prior
        business experience.
      </WppTypography>
    ),
    image: 'forecast',
  },
  {
    id: '4',
    title: 'Benchmarks',
    content: (
      <WppTypography type="s-body">
        Industry benchmarks can tell you whether you are matching the profit margins of your peers, keeping too much
        inventory on hand, or getting paid faster or slower than others.
        <br />
        Just tell LivePlan your industry, location, and company size, and we will show you recent industry averages for
        these and other key metrics. This data is generated through our partnership with Sageworks, a leading provider
        of industry data in North America.
      </WppTypography>
    ),
    image: 'benchmarks',
  },
  {
    id: '5',
    title: 'Dashboard',
    content: (
      <WppTypography type="s-body">
        LivePlan's business intelligence Dashboard gives you the insights you need to grow. It integrates with
        QuickBooks Online and Xero so there is no data entry— just connect your accounting system and track your
        progress.
        <br />
        Compare the projections in your business plan to your actual financials. See how your revenue measures up to
        projections and quickly make adjustments as things change. Your Dashboard will always be your most honest
        advisor.
      </WppTypography>
    ),
    image: 'dashboard',
  },
]
