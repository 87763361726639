// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gK9jf{width:auto;height:100%;overflow:hidden;cursor:pointer}.cUBTw{width:100%;height:100%}.c7fC2::part(content){top:65%;width:60vw}.Y4AyZ{height:100%}.mRUig{position:absolute;bottom:33px;left:33px;z-index:100;display:flex;flex-direction:column;gap:8px}.aS2fb{width:33px;height:33px;background:var(--wpp-grey-color-000);border-radius:8px;box-shadow:var(--wpp-box-shadow-xs)}.KbYZD{vertical-align:middle}", "",{"version":3,"sources":["webpack://./src/components/common/imageFullscreenModal/ImageFullscreenModal.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CACA,WAAA,CACA,eAAA,CACA,cAAA,CAGF,OACE,UAAA,CACA,WAAA,CAIA,sBACE,OAAA,CACA,UAAA,CAIJ,OACE,WAAA,CAGF,OACE,iBAAA,CACA,WAAA,CACA,SAAA,CACA,WAAA,CACA,YAAA,CACA,qBAAA,CACA,OAAA,CAGF,OACE,UAAA,CACA,WAAA,CACA,oCAAA,CACA,iBAAA,CACA,mCAAA,CAGF,OACE,qBAAA","sourcesContent":[".imgWrapper {\n  width: auto;\n  height: 100%;\n  overflow: hidden;\n  cursor: pointer;\n}\n\n.img {\n  width: 100%;\n  height: 100%;\n}\n\n.modal {\n  &::part(content) {\n    top: 65%;\n    width: 60vw;\n  }\n}\n\n.height100 {\n  height: 100%;\n}\n\n.tools {\n  position: absolute;\n  bottom: 33px;\n  left: 33px;\n  z-index: 100;\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.iconBox {\n  width: 33px;\n  height: 33px;\n  background: var(--wpp-grey-color-000);\n  border-radius: 8px;\n  box-shadow: var(--wpp-box-shadow-xs);\n}\n\n.icon {\n  vertical-align: middle;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imgWrapper": "gK9jf",
	"img": "cUBTw",
	"modal": "c7fC2",
	"height100": "Y4AyZ",
	"tools": "mRUig",
	"iconBox": "aS2fb",
	"icon": "KbYZD"
};
export default ___CSS_LOADER_EXPORT___;
