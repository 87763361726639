import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { persistQueryClient } from '@tanstack/react-query-persist-client'
import { MicroAppCustomProps } from '@wpp-open/core'
import { OsProvider } from '@wpp-open/react'
import { compress, decompress } from 'lz-string'
import { StrictMode } from 'react'

import { App } from 'app/App'
import { ApiProvider } from 'providers/api/ApiProvider'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
})

const REACT_QUERY_DEV_OFFLINE_CACHE_KEY = 'WPP_INVESTMENT_PROCESS_QUERY_API_CACHE_KEY'

// We don't use isDevelopment from environment here due to inapropriate tree shaking
if (process.env.NODE_ENV === 'development') {
  persistQueryClient({
    queryClient,
    persister: createSyncStoragePersister({
      storage: window.sessionStorage,
      key: REACT_QUERY_DEV_OFFLINE_CACHE_KEY,
      serialize: (data: any) => compress(JSON.stringify(data)),
      deserialize: (data: any) => JSON.parse(decompress(data)!),
    }),
  })
}

export const Root = (props: MicroAppCustomProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <StrictMode>
        <OsProvider {...props}>
          <ApiProvider>
            <App />
          </ApiProvider>
        </OsProvider>
      </StrictMode>
    </QueryClientProvider>
  )
}
