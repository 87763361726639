export interface ItemProperties {
  value: string
  first?: boolean
  step?: number
}

export interface Item {
  item?: ItemProperties
}

export const pages = [
  {
    id: 1,
    title: 'Assessment of Benefits Matrix',
    items: [
      {
        value: 'Current work enablement benefits',
        first: true,
      },
      {
        step: 1,
        value: 'Low enablement of our current approach. Does not add real value to our day to day work',
      },
      {
        step: 2,
        value: 'Adds some vaue to our current approach, but only locally',
      },
      {
        step: 3,
        value: 'Adds high value to our approach locally with no global expansion potential',
      },
      {
        step: 4,
        value: 'Adds high value to our current approach locally with global expansion potential',
      },
      {
        step: 5,
        value: 'Adds value to our current approach globally',
      },
      {
        value: 'Added revenue benefits',
        first: true,
      },
      {
        step: 1,
        value: 'Low or no added revenue benefit',
      },
      {
        step: 2,
        value: 'Moderate local added revenue benefit',
      },
      {
        step: 3,
        value: 'High local added revenue benefit with no global expansion potential',
      },
      {
        step: 4,
        value: 'High local added revenue benefit with global expansion potential',
      },
      {
        step: 5,
        value: 'Global added revenue benefit',
      },
      {
        value: 'Cost saving benefits',
        first: true,
      },
      {
        step: 1,
        value: 'Low or no cost-saving benefit',
      },
      {
        step: 2,
        value: 'Moderate local cost-saving benefit',
      },
      {
        step: 3,
        value: 'High local cost-saving benefit with no global expansion potential',
      },
      {
        step: 4,
        value: 'High local cost-saving benefit with global expansion potential',
      },
      {
        step: 5,
        value: 'Global cost saving benefit',
      },
      {
        value: 'Productisation potential',
        first: true,
      },
      {
        step: 1,
        value: 'Low or no productisation benefit',
      },
      {
        step: 2,
        value: 'Moderate local productisation benefit',
      },
      {
        step: 3,
        value: 'High local productisation benefit with no global expansion potential',
      },
      {
        step: 4,
        value: 'High local productisation benefit with global expansion potential',
      },
      {
        step: 5,
        value: 'Global productisation potential',
      },
    ],
  },
  {
    id: 2,
    title: 'Investment evaluation',
    items: [
      {
        value: 'Internal resource requirements',
        first: true,
      },
      {
        step: 1,
        value: 'No internal research requirements',
      },
      {
        step: 2,
        value: 'Low internal local resource requirements',
      },
      {
        step: 3,
        value: 'Moderate internal local resource requirements',
      },
      {
        step: 4,
        value: 'High internal local resource requirements',
      },
      {
        step: 5,
        value: 'Global internal resource requirements',
      },
      {
        value: 'Internal operational costs',
        first: true,
      },
      {
        step: 1,
        value: 'No operational costs',
      },
      {
        step: 2,
        value: 'Low operational costs',
      },
      {
        step: 3,
        value: 'Moderate operational costs',
      },
      {
        step: 4,
        value: 'High operational costs',
      },
      undefined,
      {
        value: 'Internal CAPEX investment',
        first: true,
      },
      {
        step: 1,
        value: 'No CAPEX investment',
      },
      {
        step: 2,
        value: 'Low CAPEX investment',
      },
      {
        step: 3,
        value: 'Moderate No CAPEX investment',
      },
      {
        step: 4,
        value: 'High CAPEX investment',
      },
      undefined,
      {
        value: 'External costs',
        first: true,
      },
      {
        step: 1,
        value: 'No external costs',
      },
      {
        step: 2,
        value: 'Low external costs',
      },
      {
        step: 3,
        value: 'Moderate external costs',
      },
      {
        step: 4,
        value: 'High external costs',
      },
      undefined,
      {
        value: 'Project duration',
        first: true,
      },
      {
        step: 1,
        value: '1-3 months',
      },
      {
        step: 2,
        value: '3-6 months',
      },
      {
        step: 3,
        value: '6-12 months',
      },
      {
        step: 4,
        value: '1-2 years',
      },
      {
        step: 5,
        value: '2 years and above',
      },
    ],
  },
]
