import { WppBreadcrumb, WppButton, WppCard, WppGrid, WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'

import styles from 'components/common/categorizationModal/CategorizationSideModal.module.scss'
import { analysisCards, evaluatorRoutes } from 'components/common/categorizationModal/data'
import { Flex } from 'components/common/flex/Flex'

export const AnalysisPage = () => {
  const navigate = useNavigate()

  const handleRouteChange = (event: CustomEvent) => {
    navigate(event.detail.path)
  }

  return (
    <>
      <div slot="header">
        <WppTypography type="2xl-heading" tag="h3" slot="header">
          Categorisation Matrix
        </WppTypography>
        <br />
        <WppBreadcrumb items={evaluatorRoutes} onWppChange={handleRouteChange} className={styles.breadcrumb} />
      </div>
      <div slot="body" className={styles.paddingBody}>
        <WppTypography type="4xl-display" tag="h1" className={styles.title}>
          Analysis
        </WppTypography>
        <WppGrid container fluid rowSpacing={8} columnSpacing={8}>
          {analysisCards.map(card => (
            <WppGrid item all={12} key={card.id}>
              <WppCard className={styles.card}>
                <Flex gap={18} justify="start">
                  <div className={styles.cardIcon}>
                    <img src={card.icon} alt={card.title} />
                  </div>
                  <WppTypography type="l-strong" tag="span">
                    {card.title}
                  </WppTypography>
                </Flex>
                <WppTypography type="s-strong" tag="span" className={clsx(styles.padding20, styles.breakWord)}>
                  {card.description}
                </WppTypography>
                <Flex justify="end" className={styles.learnMoreBtn}>
                  <WppButton variant="primary" size="m" onClick={() => navigate(card.link)}>
                    Learn More
                  </WppButton>
                </Flex>
              </WppCard>
            </WppGrid>
          ))}
        </WppGrid>
      </div>
    </>
  )
}
