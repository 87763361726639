import { WppButton, WppSideModal, WppTypography } from '@platform-ui-kit/components-library-react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { BigBox } from 'components/common/endToEndSideModal/BigBox'
import styles from 'components/common/endToEndSideModal/EndToEndSideModal.module.scss'
import { SmallBox } from 'components/common/endToEndSideModal/SmallBox'
import { Flex } from 'components/common/flex/Flex'
import { routesManager } from 'utils/routesManager'

import { steps } from './data'

export interface Props {
  isOpen: boolean
  handleCloseModal: () => void
  handleOpenModal: () => void
}

export const EndToEndSideModal = ({ isOpen, handleCloseModal, handleOpenModal }: Props) => {
  const navigate = useNavigate()
  const [selectedStep, setSelectedStep] = useState(0)

  const getStepsArray = (step: number, isBefore: boolean) => {
    const stepObj = steps[step - 1]
    if (isBefore) {
      return stepObj?.stepsBefore.map(s => steps[s - 1])
    } else {
      return stepObj?.stepsAfter.map(s => steps[s - 1])
    }
  }

  const handleStepClick = (id: number) => {
    setSelectedStep(id)
  }

  return (
    <WppSideModal
      open={isOpen}
      onWppSideModalClose={handleCloseModal}
      onWppSideModalOpen={handleOpenModal}
      size="2xl"
      className={styles.modal}
    >
      <WppTypography type="2xl-heading" tag="h3" slot="header">
        End-to-end process
      </WppTypography>
      <div slot="body" className={styles.modalBody}>
        <WppTypography type="3xl-heading" tag="h2" className={styles.description}>
          We have created a 5-step guide, making the process transparent, easy and standardised for all
        </WppTypography>
        <Flex className={styles.boxes}>
          {selectedStep === 0 ? (
            <>
              {steps.map((step, index) => (
                <SmallBox step={step} key={step.name} isImageTop={index % 2 === 0} handleStepClick={handleStepClick} />
              ))}
            </>
          ) : (
            <>
              {getStepsArray(selectedStep, true).map(step => (
                <SmallBox
                  step={step}
                  key={step.name}
                  isImageTop={false}
                  handleStepClick={handleStepClick}
                  direction="left"
                />
              ))}
              <BigBox step={steps[selectedStep - 1]} handleStepClick={handleStepClick} />
              {getStepsArray(selectedStep, false).map(step => (
                <SmallBox step={step} key={step.name} isImageTop={false} handleStepClick={handleStepClick} />
              ))}
            </>
          )}
        </Flex>
      </div>
      <Flex slot="actions" justify="end" gap={12}>
        {selectedStep === 0 ? (
          <WppButton variant="primary" size="m" onClick={() => setSelectedStep(1)}>
            View the process
          </WppButton>
        ) : (
          <>
            <WppButton variant="secondary" size="m" onClick={() => setSelectedStep(0)}>
              Back to all steps
            </WppButton>
            <WppButton
              variant="primary"
              size="m"
              onClick={() => navigate(routesManager.steps.step.getURL({ id: selectedStep }))}
            >
              More about Step {selectedStep}
            </WppButton>
          </>
        )}
      </Flex>
    </WppSideModal>
  )
}
