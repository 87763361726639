import {
  WppBreadcrumb,
  WppButton,
  WppCard,
  WppIconChevron,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { MouseEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { EndToEndSideModal } from 'components/common/endToEndSideModal/EndToEndSideModal'
import { Flex } from 'components/common/flex/Flex'
import { SmallStepCard } from 'components/common/smallStepCard/SmallStepCard'
import styles from 'pages/steps/StepsOverviewPage.module.scss'
import { routesManager } from 'utils/routesManager'

import { data } from '../../data'

export const StepsOverviewPage = () => {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  const items = [
    {
      label: 'Dashboard',
      path: routesManager.dashboard.root.getURL(),
    },
  ]

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleRouteChange = (event: CustomEvent) => {
    console.log(event.detail)
    navigate(event.detail.path)
  }

  const handleClick = (event: MouseEvent, stepNumber?: number) => {
    event.preventDefault()
    if (stepNumber) navigate(routesManager.steps.step.getURL({ id: stepNumber }))
  }

  return (
    <div className={styles.pageContent}>
      <Flex>
        <WppIconChevron color="var(--wpp-grey-color-600)" direction="left" />
        <WppBreadcrumb className={styles.breadcrumb} items={items} middleTruncation onWppChange={handleRouteChange} />
      </Flex>

      <WppTypography className={styles.title} type="4xl-display" tag="h1">
        Let's dive right in
      </WppTypography>
      <WppCard className={styles.cards}>
        <Flex className={styles.marginBottom}>
          <div className={styles.items40}>
            <WppTypography className={styles.cardDescription} type="m-midi" tag="span">
              To simplify your product investment application, we have created this 5-step guide - making the process
              transparent, easy and standardised for all.
            </WppTypography>
            <br />
            <WppTypography className={styles.cardDescription} type="s-body" tag="span">
              Each of the five steps in your application journey contains tools, activities and timeframes. To get
              started, take a look at he steps outlined below. Then click through each one to learn more about the
              process.
            </WppTypography>
          </div>
          <div className={clsx(styles.items60, styles.processBtn)}>
            <WppButton variant="secondary" className={styles.processBtnColor} onClick={handleOpen}>
              View the end-to-end process
            </WppButton>
          </div>
        </Flex>
      </WppCard>

      <WppCard className={styles.cards}>
        <Flex gap={32} justify="between" className={styles.marginBottom}>
          {data.steps.map(step => (
            <SmallStepCard
              key={step.cardTitle}
              title={step.cardTitle}
              subtitle={step.cardSubtitle}
              image={step.cardImage}
              color={step.cardColor}
              description={step.cardDescription}
              stepNumber={step.id}
              handleClick={handleClick}
            />
          ))}
        </Flex>
      </WppCard>
      <EndToEndSideModal isOpen={isOpen} handleCloseModal={handleClose} handleOpenModal={handleOpen} />
    </div>
  )
}
