import {
  WppIconFullscreenMinimise,
  WppIconZoomIn,
  WppIconZoomOut,
  WppSideModal,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { useState } from 'react'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'

import styles from 'components/common/imageFullscreenModal/ImageFullscreenModal.module.scss'

export interface Props {
  img: string
  title: string
}

export const ImageFullscreenModal = ({ img, title }: Props) => {
  const [opened, setOpened] = useState<boolean>(false)
  const handleCloseModal = () => {
    setOpened(false)
  }

  const handleOpenModal = () => {
    setOpened(true)
  }

  return (
    <>
      <div className={styles.imgWrapper}>
        <img
          src={require(`assets/${img}.png`)}
          alt="smallSizeFlowImage"
          onClick={handleOpenModal}
          className={styles.img}
        />
      </div>
      <WppSideModal
        open={opened}
        className={styles.modal}
        onWppSideModalClose={handleCloseModal}
        onWppSideModalOpen={handleOpenModal}
        size="2xl"
      >
        <WppTypography type="2xl-heading" tag="h3" slot="header">
          {title}
        </WppTypography>
        <div slot="body" className={styles.height100}>
          <div className={styles.imgWrapper}>
            <TransformWrapper initialScale={1} initialPositionX={0} initialPositionY={0}>
              {({ zoomIn, zoomOut, resetTransform }) => (
                <>
                  <div className={styles.tools}>
                    <button onClick={() => zoomIn()} className={styles.iconBox}>
                      <WppIconZoomIn className={styles.icon} />
                    </button>
                    <button onClick={() => zoomOut()} className={styles.iconBox}>
                      <WppIconZoomOut className={styles.icon} />
                    </button>
                    <button onClick={() => resetTransform()} className={styles.iconBox}>
                      <WppIconFullscreenMinimise className={styles.icon} />
                    </button>
                  </div>
                  <TransformComponent wrapperClass={styles.height100}>
                    <img src={require(`assets/${img}.png`)} alt="fullSizeFlowImage" className={styles.img} />
                  </TransformComponent>
                </>
              )}
            </TransformWrapper>
          </div>
        </div>
      </WppSideModal>
    </>
  )
}
