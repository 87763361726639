import { WppButton, WppCard, WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { MouseEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import accelerateIcon from 'assets/accelerate.svg'
import distributeIcon from 'assets/distribute.svg'
import logo from 'assets/logo.svg'
import supportIcon from 'assets/support.svg'
import { EndToEndSideModal } from 'components/common/endToEndSideModal/EndToEndSideModal'
import { Flex } from 'components/common/flex/Flex'
import { SmallStepCard } from 'components/common/smallStepCard/SmallStepCard'
import styles from 'pages/dashboard/DashboardPage.module.scss'
import { routesManager } from 'utils/routesManager'

import { data } from '../../data'

const cardsDescriptions = [
  {
    title: 'Enable and accelerate',
    description:
      'Empower those products and teams who are in the start-up phase but have not yet begun to generate revenue.',
    icon: accelerateIcon,
  },
  {
    title: 'Guide and support',
    description:
      'Maximise the potential of your products by leveraging our centralised guidance and support for network visibility and distribution.',
    icon: supportIcon,
  },
  {
    title: 'Distribute resources',
    description:
      'To distribute and redistribute resources to product areas and opportunities that meet the needs of clients and the industry.',
    icon: distributeIcon,
  },
]

export const DashboardPage = () => {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)

  const handleClick = (event: MouseEvent, stepNumber?: number) => {
    event.preventDefault()
    if (stepNumber) navigate(routesManager.steps.step.getURL({ id: stepNumber }))
  }

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <div className={styles.pageContent}>
      <WppCard className={clsx(styles.cards, styles.borderBottom)}>
        <Flex className={styles.paddingBottom}>
          <div className={styles.items40}>
            <img src={logo} alt="logo" width="80%" height="80%" />
          </div>
          <div className={styles.items60}>
            <WppTypography className={clsx(styles.cardTitle, styles.paddingBottom)} type="l-strong" tag="h4">
              The purpose of the WPP Funding Process
            </WppTypography>
            <Flex gap={32}>
              {cardsDescriptions.map(card => (
                <WppCard className={styles.card} key={card.title} variant="secondary">
                  <div className={styles.cardIcon}>
                    <img src={card.icon} alt={card.title} />
                  </div>
                  <WppTypography className={styles.cardTitle} type="m-strong" tag="h4">
                    {card.title}
                  </WppTypography>
                  <WppTypography className={styles.cardDescription} type="xs-body" tag="span">
                    {card.description}
                  </WppTypography>
                </WppCard>
              ))}
            </Flex>
          </div>
        </Flex>
      </WppCard>

      <WppCard className={clsx(styles.cards, styles.borderBottom)}>
        <Flex className={styles.paddingBottom}>
          <div className={clsx(styles.items40, styles.dashboardItemAutoMargin)}>
            <WppTypography className={styles.cardDescription} type="l-strong" tag="h3">
              We have created a 5-step guide, making the process transparent, easy and standardised for all
            </WppTypography>
          </div>
          <Flex className={clsx(styles.items60, styles.processBtn)} gap={20} justify="end">
            <WppButton variant="secondary" className={styles.processBtnColor} size="s" onClick={() => handleOpen()}>
              View the end-to-end process
            </WppButton>
            <WppButton
              variant="secondary"
              className={styles.processBtnColor}
              size="s"
              onClick={() => navigate(routesManager.steps.step.getURL({ id: 1 }))}
            >
              Start the process
            </WppButton>
          </Flex>
        </Flex>
        <Flex className={styles.paddingBottom} gap={32}>
          {data.steps.map(step => (
            <SmallStepCard
              key={step.cardTitle}
              title={step.cardTitle}
              subtitle={step.cardSubtitle}
              image={step.cardImage}
              color={step.cardColor}
              stepNumber={step.id}
              handleClick={handleClick}
            />
          ))}
        </Flex>
      </WppCard>

      <WppCard className={styles.cards}>
        <Flex className={styles.paddingBottom}>
          <div className={clsx(styles.items40, styles.dashboardItemAutoMargin)}>
            <WppTypography className={styles.cardDescription} type="l-strong" tag="span">
              Useful links/Sections
            </WppTypography>
          </div>
          <Flex className={styles.items60} gap={32}>
            <WppButton
              variant="primary"
              className={styles.navigationBtn}
              size="m"
              onClick={() => navigate(routesManager.liveplan.root.getURL())}
            >
              LivePlan
            </WppButton>
            <WppButton
              variant="primary"
              className={styles.navigationBtn}
              size="m"
              onClick={() => navigate(routesManager.evaluatorTools.root.getURL())}
            >
              Evaluator tools
            </WppButton>
            <WppButton
              variant="primary"
              className={styles.navigationBtn}
              size="m"
              onClick={() => navigate(routesManager.contact.root.getURL())}
            >
              Contact
            </WppButton>
          </Flex>
        </Flex>
      </WppCard>

      <EndToEndSideModal isOpen={isOpen} handleCloseModal={handleClose} handleOpenModal={handleOpen} />
    </div>
  )
}
