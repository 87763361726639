// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".J4VFr{color:var(--wpp-primary-color-400);display:block}.ZNU0_{width:auto;overflow:hidden}.ZNU0_>img{width:100%;height:100%;object-fit:cover}", "",{"version":3,"sources":["webpack://./src/Data.module.scss"],"names":[],"mappings":"AAAA,OACE,kCAAA,CACA,aAAA,CAGF,OACE,UAAA,CACA,eAAA,CAGF,WACE,UAAA,CACA,WAAA,CACA,gBAAA","sourcesContent":[".teamText {\n  color: var(--wpp-primary-color-400);\n  display: block;\n}\n\n.imgWrapper {\n  width: auto;\n  overflow: hidden;\n}\n\n.imgWrapper > img {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"teamText": "J4VFr",
	"imgWrapper": "ZNU0_"
};
export default ___CSS_LOADER_EXPORT___;
