import { WppBreadcrumb, WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'

import categorisationMatrix from 'assets/categorisation-matrix-bckg.png'
import styles from 'components/common/categorizationModal/CategorizationSideModal.module.scss'
import { detailsCards } from 'components/common/categorizationModal/data'
import { Flex } from 'components/common/flex/Flex'
import { routesManager } from 'utils/routesManager'

export const DetailsPage = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const pageList = {
    fitAndBenefit: detailsCards.find(card => card.id === 1),
    complexityAndExperience: detailsCards.find(card => card.id === 2),
    scaleAndAdoption: detailsCards.find(card => card.id === 3),
    salesAndCX: detailsCards.find(card => card.id === 4),
  }

  const fitAndBenefitPath =
    matchPath(routesManager.evaluatorTools.fitAndBenefit.pattern, pathname) !== null
      ? pageList.fitAndBenefit
      : undefined
  const complexityAndExperiencePath =
    matchPath(routesManager.evaluatorTools.complexityAndExperience.pattern, pathname) !== null
      ? pageList.complexityAndExperience
      : undefined
  const scaleAndAdoptionPath =
    matchPath(routesManager.evaluatorTools.scaleAndAdoption.pattern, pathname) !== null
      ? pageList.scaleAndAdoption
      : undefined
  const salesAndCXPath =
    matchPath(routesManager.evaluatorTools.salesAndCX.pattern, pathname) !== null ? pageList.salesAndCX : undefined

  const page = fitAndBenefitPath ?? complexityAndExperiencePath ?? scaleAndAdoptionPath ?? salesAndCXPath ?? undefined

  const evaluatorRoutes = [
    {
      label: 'Categorisation Matrix',
      path: routesManager.evaluatorTools.landing.getURL(),
    },
    {
      label: 'Analysis',
      path: routesManager.evaluatorTools.analysis.getURL(),
    },
    {
      label: page?.title ?? '',
      path: pathname,
    },
  ]

  const handleRouteChange = (event: CustomEvent) => {
    navigate(event.detail.path)
  }

  return (
    <>
      <div slot="header">
        <WppTypography type="2xl-heading" tag="h3" slot="header">
          Categorisation Matrix
        </WppTypography>
        <br />
        <WppBreadcrumb items={evaluatorRoutes} onWppChange={handleRouteChange} className={styles.breadcrumb} />
      </div>
      <div slot="body" className={clsx(styles.paddingDetailsPage, styles.height100)}>
        <Flex className={styles.height100} gap={4} justify="between">
          <div className={styles.content}>
            <Flex gap={12} justify="start">
              <div className={styles.cardBigIcon}>
                <img src={page?.icon} alt={page?.title} className={styles.icon} />
              </div>
              <WppTypography type="4xl-display" tag="h1" className={styles.title2}>
                {page?.title}
              </WppTypography>
            </Flex>
            <div>{page?.description}</div>
          </div>
          <div className={styles.imageDiv}>
            <img src={categorisationMatrix} alt="categorisationMatrix" width="100%" height="100%" />
            <div className={styles.diagram}>
              <img src={page?.image} alt={page?.title} width="90%" height="90%" />
            </div>
          </div>
        </Flex>
      </div>
    </>
  )
}
