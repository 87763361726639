// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ALIkN{display:flex;align-items:stretch;max-width:440px}.ALIkN::part(card){padding:0}.KVKnC{margin:auto 0;padding-right:66px;padding-left:28px}.CYRm3{display:block;color:var(--wpp-primary-color-400)}.VHaX8{text-decoration-line:underline;display:block;cursor:pointer}.uhkyY{display:flex;justify-content:flex-start}", "",{"version":3,"sources":["webpack://./src/components/common/contactCard/ContactCard.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,mBAAA,CACA,eAAA,CAEA,mBACE,SAAA,CAIJ,OACE,aAAA,CACA,kBAAA,CACA,iBAAA,CAGF,OACE,aAAA,CACA,kCAAA,CAGF,OACE,8BAAA,CACA,aAAA,CACA,cAAA,CAGF,OACE,YAAA,CACA,0BAAA","sourcesContent":[".card {\n  display: flex;\n  align-items: stretch;\n  max-width: 440px;\n\n  &::part(card) {\n    padding: 0;\n  }\n}\n\n.content {\n  margin: auto 0;\n  padding-right: 66px;\n  padding-left: 28px;\n}\n\n.cardTitle {\n  display: block;\n  color: var(--wpp-primary-color-400);\n}\n\n.mail {\n  text-decoration-line: underline;\n  display: block;\n  cursor: pointer;\n}\n\n.imageDiv {\n  display: flex;\n  justify-content: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "ALIkN",
	"content": "KVKnC",
	"cardTitle": "CYRm3",
	"mail": "VHaX8",
	"imageDiv": "uhkyY"
};
export default ___CSS_LOADER_EXPORT___;
