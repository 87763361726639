// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Ss8q_{display:flex;flex:1 1}.Ss8q_::part(card){padding:0}.epzWb{padding:15px 20px}.lRNaw{display:block}.BNZh4{display:block;padding:20px 0}.ozMSf{display:flex;justify-content:flex-end}.YkWct{width:80%;overflow:hidden}.gQqhW{overflow:hidden}.gQqhW>img{width:105%;height:105%;margin-left:-2px}.YkWct>img{width:105%;height:101%}", "",{"version":3,"sources":["webpack://./src/components/common/smallStepCard/SmallStepCard.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,QAAA,CAEA,mBACE,SAAA,CAIJ,OACE,iBAAA,CAGF,OACE,aAAA,CAGF,OACE,aAAA,CACA,cAAA,CAGF,OACE,YAAA,CACA,wBAAA,CAGF,OACE,SAAA,CACA,eAAA,CAGF,OACE,eAAA,CAGF,WACE,UAAA,CACA,WAAA,CACA,gBAAA,CAGF,WACE,UAAA,CACA,WAAA","sourcesContent":[".card {\n  display: flex;\n  flex: 1 1 0;\n\n  &::part(card) {\n    padding: 0;\n  }\n}\n\n.content {\n  padding: 15px 20px;\n}\n\n.cardTitle {\n  display: block;\n}\n\n.description {\n  display: block;\n  padding: 20px 0;\n}\n\n.imgWrapper {\n  display: flex;\n  justify-content: flex-end;\n}\n\n.imageDiv80 {\n  width: 80%;\n  overflow: hidden;\n}\n\n.imageDiv {\n  overflow: hidden;\n}\n\n.imageDiv > img {\n  width: 105%;\n  height: 105%;\n  margin-left: -2px;\n}\n\n.imageDiv80 > img {\n  width: 105%;\n  height: 101%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "Ss8q_",
	"content": "epzWb",
	"cardTitle": "lRNaw",
	"description": "BNZh4",
	"imgWrapper": "ozMSf",
	"imageDiv80": "YkWct",
	"imageDiv": "gQqhW"
};
export default ___CSS_LOADER_EXPORT___;
