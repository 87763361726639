import { WppButton, WppCard, WppGrid, WppTypography } from '@platform-ui-kit/components-library-react'
import { useNavigate } from 'react-router-dom'

import styles from 'components/common/categorizationModal/CategorizationSideModal.module.scss'
import { landingCards } from 'components/common/categorizationModal/data'
import { Flex } from 'components/common/flex/Flex'
import { routesManager } from 'utils/routesManager'

export const LandingPage = () => {
  const navigate = useNavigate()

  return (
    <>
      <div slot="header">
        <WppTypography type="2xl-heading" tag="h3" slot="header">
          Categorisation Matrix
        </WppTypography>
      </div>
      <div slot="body" className={styles.paddingLandingPage}>
        <WppGrid container fluid rowSpacing={8} columnSpacing={8}>
          {landingCards.map(card => (
            <WppGrid item all={12} key={card.id}>
              <WppCard className={styles.card}>
                <Flex gap={18} justify="start" className={styles.padding30}>
                  <div className={styles.cardIcon}>
                    <img src={card.icon} alt={card.title} />
                  </div>
                  <WppTypography type="l-strong" tag="span">
                    {card.title}
                  </WppTypography>
                </Flex>
                <WppTypography type="s-body" tag="span">
                  {card.items}
                </WppTypography>
              </WppCard>
            </WppGrid>
          ))}
        </WppGrid>
      </div>
      <Flex justify="end" slot="actions">
        <WppButton variant="primary" size="m" onClick={() => navigate(routesManager.evaluatorTools.analysis.getURL())}>
          View Analysis
        </WppButton>
      </Flex>
    </>
  )
}
