import { WppIconChevron, WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'

import { Step } from 'components/common/endToEndSideModal/data'
import styles from 'components/common/endToEndSideModal/EndToEndSideModal.module.scss'

export interface Props {
  step: Step
  isImageTop?: boolean
  direction?: 'left' | 'right'
  handleStepClick: (id: number) => void
}

export const SmallBox = ({ step, isImageTop = true, direction = 'right', handleStepClick }: Props) => {
  const StepName = () => (
    <WppTypography
      type="2xl-heading"
      tag="h4"
      className={clsx(styles.stepTitle, isImageTop ? styles.padding60Top : styles.padding60Bottom)}
    >
      {step.name}
    </WppTypography>
  )

  const StepChevron = () => (
    <div className={styles.stepCircle} style={{ background: step.color }} onClick={() => handleStepClick(step.id)}>
      <WppIconChevron color="var(--wpp-grey-color-000)" direction={direction} />
    </div>
  )

  const StepImage = () => (
    <div>
      <img src={step.image} alt={step.name} width="100%" height="100%" />
    </div>
  )

  return (
    <div className={styles.step}>
      {isImageTop ? (
        <>
          <StepName />
          <StepChevron />
          <StepImage />
        </>
      ) : (
        <>
          <StepImage />
          <StepChevron />
          <StepName />
        </>
      )}
    </div>
  )
}
