import { WppCard, WppTag, WppTypography } from '@platform-ui-kit/components-library-react'
import { MouseEvent } from 'react'
import { useNavigate } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'components/common/largeStepCard/LargeStepCard.module.scss'
import { routesManager } from 'utils/routesManager'

import { data } from '../../../data'

export interface Props {
  title: string
  image: string
  color: string
  description: string
  stepNumber: number
}

export const LargeStepCard = ({ title, image, description, color, stepNumber }: Props) => {
  const navigate = useNavigate()
  const handleClick = (event: MouseEvent, step: number) => {
    if (step !== stepNumber) navigate(routesManager.steps.step.getURL({ id: step }))
  }

  return (
    <WppCard className={styles.card}>
      <div style={{ background: color }}>
        <Flex justify="between">
          <div className={styles.width40}>
            <div className={styles.tags}>
              <Flex gap={13}>
                {data.steps.map(step => (
                  <WppTag
                    key={step.id}
                    variant="neutral"
                    label={step.cardTitle}
                    className={step.id === stepNumber ? styles.selectedTag : styles.notSelectedTag}
                    onClick={event => handleClick(event, step.id)}
                  />
                ))}
              </Flex>
            </div>
            <div className={styles.content}>
              <WppTypography className={styles.cardTitle} type="4xl-display" tag="h1">
                {title}
              </WppTypography>
              <WppTypography className={styles.description} type="m-midi" tag="span">
                {description}
              </WppTypography>
            </div>
          </div>

          <div className={styles.imageDiv}>
            <img src={require(`assets/${image}.png`)} alt={title} />
          </div>
        </Flex>
      </div>
    </WppCard>
  )
}
