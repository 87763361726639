import { WppButton, WppExpandableCard, WppTypography } from '@platform-ui-kit/components-library-react'
import { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import styles from 'components/common/dropdownToolbar/DropdownToolbar.module.scss'

type Buttons = { name: string; route: string }[]

export interface Props {
  title: string
  value?: ReactElement
  buttons?: Buttons
  opened: boolean
}

export const DropdownToolbar = ({ title, value, buttons, opened }: Props) => {
  const navigate = useNavigate()

  return (
    <WppExpandableCard size="s" className={styles.expandableCard} expanded={opened}>
      <WppTypography type="s-strong" slot="header">
        {title}
      </WppTypography>
      <div className={styles.buttons}>
        {value}
        {buttons &&
          buttons.map(item => (
            <WppButton key={item.name} className={styles.margin7} size="s" onClick={() => navigate(item.route)}>
              {item.name}
            </WppButton>
          ))}
      </div>
    </WppExpandableCard>
  )
}
