interface getURLParams {
  id: string | number
}

export const routesManager = {
  dashboard: {
    root: {
      pattern: 'dashboard',
      getURL: () => '/dashboard',
    },
  },
  steps: {
    root: {
      pattern: 'steps',
      getURL: () => '/steps',
    },
    step: {
      pattern: 'steps/:stepId',
      getURL: ({ id }: getURLParams) => `/steps/${id}`,
    },
  },
  liveplan: {
    root: {
      pattern: 'liveplan',
      getURL: () => '/liveplan',
    },
  },
  evaluatorTools: {
    root: {
      pattern: 'evaluator-tools/*',
      getURL: () => '/evaluator-tools',
    },
    landing: {
      pattern: 'evaluator-tools',
      getURL: () => '/evaluator-tools',
    },
    categorisation: {
      pattern: 'evaluator-tools/categorisation',
      getURL: () => '/evaluator-tools/categorisation',
    },
    benefits: {
      pattern: 'evaluator-tools/benefits',
      getURL: () => '/evaluator-tools/benefits',
    },
    analysis: {
      pattern: 'evaluator-tools/analysis',
      getURL: () => '/evaluator-tools/analysis',
    },
    details: {
      pattern: 'evaluator-tools/analysis/:pageId',
      getURL: ({ id }: getURLParams) => `/evaluator-tools/analysis/${id}`,
    },
    fitAndBenefit: {
      pattern: 'evaluator-tools/analysis/fit-and-benefit',
      getURL: () => '/evaluator-tools/analysis/fit-and-benefit',
    },
    complexityAndExperience: {
      pattern: 'evaluator-tools/analysis/complexity-and-experience',
      getURL: () => '/evaluator-tools/analysis/complexity-and-experience',
    },
    scaleAndAdoption: {
      pattern: 'evaluator-tools/analysis/scale-and-adoption',
      getURL: () => '/evaluator-tools/analysis/scale-and-adoption',
    },
    salesAndCX: {
      pattern: 'evaluator-tools/analysis/sales-and-cx',
      getURL: () => '/evaluator-tools/analysis/sales-and-cx',
    },
  },
  contact: {
    root: {
      pattern: 'contact',
      getURL: () => '/contact',
    },
  },
  '404': {
    root: {
      pattern: '/404',
      getURL: () => '/404',
    },
  },
}
