import {
  WppBreadcrumb,
  WppButton,
  WppCard,
  WppIconChevron,
  WppIconPlay,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { MouseEvent, ReactElement, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useToggle } from 'react-use'

import logoUrl from 'assets/liveplan.svg'
import video from 'assets/video.png'
import { FeaturesSideModal } from 'components/common/featuresSideModal/FeaturesSideModal'
import { Flex } from 'components/common/flex/Flex'
import { SmallStepCard } from 'components/common/smallStepCard/SmallStepCard'
import styles from 'pages/liveplan/LiveplanPage.module.scss'
import { routesManager } from 'utils/routesManager'

import { features } from '../../data'

const cardsDescriptions = [
  {
    title: '60%',
    description: 'Planning makes newly formed business 60% more likely to succeed  in their 1sr year',
  },
  {
    title: '30%',
    description: 'Companies with a plan grow 30% faster than companies without a plan',
  },
  {
    title: '500k',
    description: 'Every month 500k companies are formed in the USA',
  },
]

const explanations = [
  {
    title: 'Why LivePlan?',
    description:
      'Agencies need a blueprint for the future to compete in a modern market. You must design the future you want in order to make it happen.',
  },
  {
    title: 'What is your plan?',
    description:
      "Research show that businesses with a plan GROW 30% FASTER than those without one. The most successful business owners don't wait for growth to happen - they take well-planed steps to improve their services, operations and delivery methods.",
  },
  {
    title: 'You need a blueprint',
    description:
      "We all know that committing to a plan is easier said than done. That's why so many business plans are drawn up only to sit in a drawer somewhere. Lean Business Planning with LivePlan creates a living plan that allows you and others you work with to perform at the highest level. By creating your blueprint, everyone shares the same vision of the future, knows how to get there and works together to realise it.",
  },
  {
    title: 'Do only what you use ',
    description:
      "LivePlan allows for agility and simplicity. It takes business planning from a vague, daunting uncertainty to specific, educated guesses, linked together so you can deal with demand, most importantly, get things done. Planning with LivePlan is a continuous process that assumes constant change, empowers accountability and allows you to do only what you'll use!",
  },
]

const smallCards = [
  {
    id: 1,
    title: 'Pitch',
    image: 'liveplan1',
  },
  {
    id: 2,
    title: 'Plan',
    image: 'liveplan2',
  },
  {
    id: 3,
    title: 'Forecast',
    image: 'liveplan3',
  },
  {
    id: 4,
    title: 'Benchmark',
    image: 'liveplan4',
  },
  {
    id: 5,
    title: 'Dashboard',
    image: 'liveplan5',
  },
]

interface Feature {
  id: number
  title: string
  content: ReactElement
  image: string
}

export const LiveplanPage = () => {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useToggle(false)
  const [selectedFeature, setSelectedFeature] = useState<Feature | null>(null)
  const liveplanURL =
    'https://wppcorp.sharepoint.com/sites/wpp-os-integration/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2Fwpp%2Dos%2Dintegration%2FShared%20Documents%2FSupport%20Documents%2FLivePlan%20Resource%20Guide%2Epdf&parent=%2Fsites%2Fwpp%2Dos%2Dintegration%2FShared%20Documents%2FSupport%20Documents'
  const liveplanVideoURL = 'https://paloaltosoftware.wistia.com/medias/tg7g5cnfn3?wvideo=tg7g5cnfn3'
  const items = [
    {
      label: 'Dashboard',
      path: routesManager.dashboard.root.getURL(),
    },
  ]

  const handleMail = (event: MouseEvent) => {
    event.preventDefault()
    window.location.href = 'mailto:melissa.gay@wpp.com'
  }

  const handleRouteChange = (event: CustomEvent) => {
    console.log(event.detail)
    navigate(event.detail.path)
  }

  const handleClick = (event: MouseEvent, stepNumber?: number) => {
    event.preventDefault()
    if (stepNumber) {
      setSelectedFeature(features[stepNumber - 1] as unknown as Feature)
      setIsOpen(true)
    }
  }

  const handleCloseModal = () => {
    setIsOpen(false)
  }

  const handleOpenModal = () => {
    setIsOpen(true)
  }

  return (
    <div className={styles.pageContent}>
      <Flex>
        <WppIconChevron color="var(--wpp-grey-color-600)" direction="left" />
        <WppBreadcrumb className={styles.breadcrumb} items={items} middleTruncation onWppChange={handleRouteChange} />
      </Flex>
      <WppCard className={styles.bigCard}>
        <Flex>
          <div className={clsx(styles.width50, styles.cardContent)}>
            <img src={logoUrl} alt="logo" height={68} width={276} />
            <WppTypography className={styles.cardDescription} type="m-midi" tag="span">
              Agencies need a blueprint for the future to compete in a modern market. You must design the future you
              want in order to make it happen.
            </WppTypography>
            <Flex justify="start" gap={20}>
              <WppButton variant="primary" size="s" onClick={handleMail}>
                Request LivePlan Account
              </WppButton>
              <WppButton variant="secondary" size="s" onClick={() => window.open(liveplanURL, 'liveplan')}>
                Visit website
              </WppButton>
            </Flex>
          </div>
          <div
            className={clsx(styles.width50, styles.relative)}
            onClick={() => window.open(liveplanVideoURL, 'liveplanVideo')}
          >
            <img src={video} width="100%" height="100%" alt="video" />
            <div className={styles.centerImage}>
              <WppIconPlay height={90} width={90} color="var(--wpp-grey-color-100)" />
            </div>
          </div>
        </Flex>
      </WppCard>

      <WppTypography className={styles.title} type="l-strong" tag="h3">
        Did you know
      </WppTypography>

      <Flex className={clsx(styles.paddingBottom, styles.marginBottom, styles.borderBottom)}>
        <Flex gap={32} className={styles.items60}>
          {cardsDescriptions.map(card => (
            <WppCard className={styles.smallCard} key={card.title}>
              <WppTypography className={styles.cardTitle} type="5xl-display" tag="h1">
                {card.title}
              </WppTypography>
              <WppTypography type="s-body" tag="span">
                {card.description}
              </WppTypography>
            </WppCard>
          ))}
        </Flex>
        <div className={clsx(styles.items40, styles.quote)}>
          <Flex gap={10}>
            <WppTypography type="5xl-display" tag="h1" className={styles.quotationMark}>
              “
            </WppTypography>
            <WppTypography type="2xl-heading" tag="h3" className={styles.quoteText}>
              Take small steps, look back, track results, see what works and change often.
            </WppTypography>
          </Flex>
        </div>
      </Flex>

      <div className={clsx(styles.paddingTop, styles.borderBottom)}>
        {explanations.map(expl => (
          <Flex key={expl.title} className={styles.paddingBottom}>
            <div className={clsx(styles.items20)}>
              <WppTypography type="l-strong" tag="h1">
                {expl.title}
              </WppTypography>
            </div>
            <div className={clsx(styles.items80)}>
              <WppTypography type="s-body" tag="span" className={styles.description}>
                {expl.description}
              </WppTypography>
            </div>
          </Flex>
        ))}
      </div>

      <WppTypography className={styles.title} type="l-strong" tag="h3">
        LivePlan features
      </WppTypography>

      <Flex gap={32} className={styles.paddingBottom}>
        {smallCards.map(card => (
          <SmallStepCard
            key={card.title}
            title={card.title}
            image={card.image}
            handleClick={handleClick}
            stepNumber={card.id}
          />
        ))}
      </Flex>
      {selectedFeature && (
        <FeaturesSideModal
          title={selectedFeature?.title}
          content={selectedFeature.content}
          image={selectedFeature.image}
          isOpen={isOpen}
          handleCloseModal={handleCloseModal}
          handleOpenModal={handleOpenModal}
        />
      )}
    </div>
  )
}
