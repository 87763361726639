import { WppCard, WppTypography } from '@platform-ui-kit/components-library-react'
import { MouseEvent } from 'react'

import styles from 'components/common/contactCard/ContactCard.module.scss'
import { Flex } from 'components/common/flex/Flex'

export interface Props {
  name: string
  title: string
  mail: string
  image: string
}

export const ContactCard = ({ name, title, image, mail }: Props) => {
  const handleMail = (event: MouseEvent) => {
    event.preventDefault()
    window.location.href = `mailto:${mail}`
  }

  return (
    <WppCard className={styles.card}>
      <Flex>
        <div className={styles.imageDiv}>
          <img src={require(`assets/${image}.png`)} alt={name} />
        </div>
        <div className={styles.content}>
          <WppTypography className={styles.cardTitle} type="m-strong" tag="h4">
            {name}
          </WppTypography>
          <WppTypography type="s-body" tag="span">
            {title}
          </WppTypography>
          <WppTypography className={styles.mail} type="s-body" tag="span" onClick={handleMail}>
            {mail}
          </WppTypography>
        </div>
      </Flex>
    </WppCard>
  )
}
