import {
  WppBreadcrumb,
  WppButton,
  WppCard,
  WppIconChevron,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { MouseEvent } from 'react'
import { useNavigate } from 'react-router-dom'

import { EvaluatorToolsCard } from 'components/common/evaluatorToolsCard/EvaluatorToolsCard'
import { Flex } from 'components/common/flex/Flex'
import { SmallStepCard } from 'components/common/smallStepCard/SmallStepCard'
import styles from 'pages/evaluator/EvaluatorToolsPage.module.scss'
import { routesManager } from 'utils/routesManager'

import { data } from '../../data'

const tools = [
  {
    id: 'benefits',
    title: 'Benefits Matrix',
    description:
      'The Benefits Matrix tool enables the CTO Product Team to qualitatively evaluate your submission and categorise it.',
    button: 'View Benefits Matrix',
    image: 'benefits-matrix',
    url: 'https://wppcorp.sharepoint.com/:b:/r/sites/wpp-os-integration/Shared%20Documents/Evaluator%20Tools/Benefits-Matrix.pdf?csf=1&web=1&e=Ef1hyu',
  },
  {
    id: 'categorisation',
    title: 'Categorisation Matrix',
    description:
      'The Categorisation Matrix is a product analysis tool which helps the CTO Product Team plot the benefits of your product, against timing and revenue potential for WPP.',
    button: 'View Categorisation Matrix',
    image: 'categorisation-matrix',
    url: 'https://wppcorp.sharepoint.com/:b:/r/sites/wpp-os-integration/Shared%20Documents/Evaluator%20Tools/Categorisation-Matrix.pdf?csf=1&web=1&e=KfB0pT',
  },
]

export const EvaluatorToolsPage = () => {
  const navigate = useNavigate()
  const items = [
    {
      label: 'Dashboard',
      path: routesManager.dashboard.root.getURL(),
    },
  ]

  const handleRouteChange = (event: CustomEvent) => {
    console.log(event.detail)
    navigate(event.detail.path)
  }

  const handleClick = (event: MouseEvent, stepNumber?: number) => {
    event.preventDefault()
    if (stepNumber) navigate(routesManager.steps.step.getURL({ id: stepNumber }))
  }

  return (
    <div className={styles.pageContent}>
      <Flex>
        <WppIconChevron color="var(--wpp-grey-color-600)" direction="left" />
        <WppBreadcrumb className={styles.breadcrumb} items={items} middleTruncation onWppChange={handleRouteChange} />
      </Flex>

      <WppTypography className={styles.title} type="4xl-display" tag="h1">
        Evaluator tools
      </WppTypography>

      <WppTypography type="m-midi" tag="span">
        Here you can find the tools that the evaluators will use to score your application.
      </WppTypography>

      <div className={clsx(styles.tools, styles.borderBottom)}>
        <Flex justify="start" className={styles.paddingBottom} gap={30}>
          {tools.map(tool => (
            <EvaluatorToolsCard
              key={tool.title}
              title={tool.title}
              image={tool.image}
              description={tool.description}
              button={tool.button}
              id={tool.id}
            />
          ))}
        </Flex>
      </div>

      <WppCard className={styles.cards}>
        <Flex className={styles.paddingBottom}>
          <div className={clsx(styles.items40, styles.dashboardItemAutoMargin)}>
            <WppTypography className={styles.cardDescription} type="l-strong" tag="h3">
              We have created a 5-step guide, making the process transparent, easy and standardised for all
            </WppTypography>
          </div>
          <div className={clsx(styles.items60, styles.processBtn)}>
            <WppButton
              className={styles.processBtnColor}
              size="s"
              onClick={() => navigate(routesManager.steps.step.getURL({ id: 1 }))}
            >
              Start the process
            </WppButton>
          </div>
        </Flex>
        <Flex className={styles.paddingBottom} gap={32}>
          {data.steps.map(step => (
            <SmallStepCard
              key={step.cardTitle}
              title={step.cardTitle}
              subtitle={step.cardSubtitle}
              image={step.cardImage}
              color={step.cardColor}
              stepNumber={step.id}
              handleClick={handleClick}
            />
          ))}
        </Flex>
      </WppCard>
    </div>
  )
}
