// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".v4kWH{height:var(--navigation-height);background-color:var(--wpp-white-color)}.Aq_lj{display:flex;align-items:center;margin-right:32px}.mIcFt{margin-left:12px;white-space:nowrap}", "",{"version":3,"sources":["webpack://./src/app/header/Header.module.scss"],"names":[],"mappings":"AAAA,OACE,+BAAA,CACA,uCAAA,CAGF,OACE,YAAA,CACA,kBAAA,CACA,iBAAA,CAGF,OACE,gBAAA,CACA,kBAAA","sourcesContent":[".wppTopBar {\n  height: var(--navigation-height);\n  background-color: var(--wpp-white-color);\n}\n\n.wppTopBarLogo {\n  display: flex;\n  align-items: center;\n  margin-right: 32px;\n}\n\n.wppTopBarTitle {\n  margin-left: 12px;\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wppTopBar": "v4kWH",
	"wppTopBarLogo": "Aq_lj",
	"wppTopBarTitle": "mIcFt"
};
export default ___CSS_LOADER_EXPORT___;
