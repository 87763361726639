import { WppGrid, WppTag, WppTypography } from '@platform-ui-kit/components-library-react'
import { MouseEvent, useState } from 'react'

import categorisationMatrix from 'assets/benefits-matrix-bckg.png'
import styles from 'components/common/benefitsModal/BenefitsSideModal.module.scss'
import { Item, pages } from 'components/common/benefitsModal/data'
import { Flex } from 'components/common/flex/Flex'

export const BenefitsSideModal = () => {
  const [selectedPage, setSelectedPage] = useState(1)

  const handleClick = (event: MouseEvent, page: number) => {
    if (page !== selectedPage) setSelectedPage(page)
  }

  const getRows = () => {
    const page = pages.find(page => page.id === selectedPage)
    return page?.items ?? []
  }

  const UndefinedItemBox = ({ index }: { index: number }) => (
    <WppGrid item all={4} key={index} className={styles.undefinedBox} />
  )

  const FirstItemBox = ({ item }: Item) => (
    <WppGrid item all={4} key={item?.value} className={styles.firstBox}>
      <WppTypography type="xs-strong" tag="span" className={styles.firstBoxText}>
        {item?.value}
      </WppTypography>
    </WppGrid>
  )

  const NormalItemBox = ({ item }: Item) => (
    <WppGrid item all={4} key={item?.value} className={styles.normalBox}>
      <div className={styles.stepNumber}>
        <WppTypography type="l-body" tag="span" className={styles.stepNumberText}>
          {item?.step}
        </WppTypography>
      </div>
      <WppTypography type="xs-body" tag="span" className={styles.normalBoxText}>
        {item?.value}
      </WppTypography>
    </WppGrid>
  )

  return (
    <>
      <div slot="header">
        <WppTypography type="2xl-heading" tag="h3" slot="header">
          Benefits Matrix
        </WppTypography>
        <br />
        <WppTypography type="m-midi" tag="span" className={styles.description}>
          The benefits matrix enables the CTO Product Team to qualitatively evaluate your submission and categorise it.
        </WppTypography>
      </div>
      <div slot="body" className={styles.height100}>
        <div className={styles.content}>
          <div className={styles.tags}>
            <Flex gap={13}>
              {pages.map(page => (
                <WppTag
                  key={page.id}
                  variant="neutral"
                  label={page.title}
                  className={page.id === selectedPage ? styles.selectedTag : styles.notSelectedTag}
                  onClick={event => handleClick(event, page.id)}
                />
              ))}
            </Flex>
          </div>
          <WppGrid container fullWidth className={styles.grid}>
            {getRows().map((item, index) => (
              <div key={index}>
                {!item ? (
                  <UndefinedItemBox index={index} />
                ) : item.first ? (
                  <FirstItemBox item={item} />
                ) : (
                  <NormalItemBox item={item} />
                )}
              </div>
            ))}
          </WppGrid>
        </div>
        <div className={styles.imageDiv}>
          <img src={categorisationMatrix} alt="categorisationMatrix" width="100%" height="100%" />
        </div>
      </div>
    </>
  )
}
