import { WppTypography } from '@platform-ui-kit/components-library-react'

import benefitsIcon from 'assets/benefits.svg'
import complexityAndExperience from 'assets/complexity-and-experience-big.svg'
import complexityAndExperienceChart from 'assets/complexity-and-experience-chart.png'
import complexityAndExperienceSmall from 'assets/complexity-and-experience.svg'
import feasibilityIcon from 'assets/feasibility.svg'
import fitAndBenefitsIcon from 'assets/fit-and-benefits-big.svg'
import fitAndBenefitsChart from 'assets/fit-and-benefits-chart.png'
import fitAndBenefitsSmallIcon from 'assets/fit-and-benefits.svg'
import resourcesIcon from 'assets/resources.svg'
import salesAndCXIcon from 'assets/sales-and-cx-big.svg'
import salesAndCxChart from 'assets/sales-and-cx-chart.png'
import salesAndCXSmallIcon from 'assets/sales-and-cx.svg'
import scaleAndAdoptionIcon from 'assets/scale-and-adoption-big.svg'
import scaleAndAdoptionChart from 'assets/scale-and-adoption-chart.png'
import scaleAndAdoptionSmallIcon from 'assets/scale-and-adoption.svg'
import wppFitIcon from 'assets/wpp-fit.svg'
import styles from 'components/common/categorizationModal/CategorizationSideModal.module.scss'
import { routesManager } from 'utils/routesManager'

interface ICard {
  id: number
  title: string
  description: JSX.Element
  image: string
  icon: string
}

export const landingCards = [
  {
    id: 1,
    title: 'Benefits',
    items: (
      <WppTypography type="s-body" tag="span">
        1. Problem solved - Size of market
        <br />
        2. Competitive advantage - share of market
        <br />
        3. Work enablement - soft benefits
        <br />
        4. Efficiencies - savings
      </WppTypography>
    ),
    icon: benefitsIcon,
  },
  {
    id: 2,
    title: 'WPP Fit',
    items: (
      <WppTypography type="s-body" tag="span">
        1. Fit with open
        <br />
        2. Synergy with other tools
        <br />
        3. Fit with workflows
        <br />
        4. Opco workflow
        <br />
        5. Pitch workflows
      </WppTypography>
    ),
    icon: wppFitIcon,
  },
  {
    id: 3,
    title: 'Resources',
    items: (
      <WppTypography type="s-body" tag="span">
        1. CAPEX
        <br />
        2. OBEX
        <br />
        3. Internal
        <br />
        4. Finance and commerce
        <br />
        5. CX/TOM
      </WppTypography>
    ),
    icon: resourcesIcon,
  },
  {
    id: 4,
    title: 'Benefits',
    items: (
      <WppTypography type="s-body" tag="span">
        1. Team capabilities to build
        <br />
        2. Partner capabilities to build
        <br />
        3. Team capabilities to scale/sell
        <br />
        4. Partner capabilities to sale/sell
        <br />
        5. Path to build
        <br />
        6. Ease of adoption
      </WppTypography>
    ),
    icon: feasibilityIcon,
  },
]

export const detailsCards: ICard[] = [
  {
    id: 1,
    title: 'Fit and Benefits',
    description: (
      <>
        <WppTypography type="s-strong" tag="span" className={styles.contentText}>
          The first analysis is on understanding the initiative’s fit with WPP’s vision, processes, and product
          architecture as well as understanding the type of benefit that will result from the initiative.
        </WppTypography>
        <br />
        <br />
        <WppTypography type="s-strong" tag="span" className={styles.contentText}>
          WPP Fit:
        </WppTypography>
        <br />
        <WppTypography type="s-body" tag="span" className={styles.contentText}>
          We look at fit from a several perspectives, from vision to process to product architecture, and others. Vision
          refers to enabling creative transformation. Process fit is an evaluation of whether the initiative will help
          with things like our production and media processes, which are more structured examples, or with our creative
          ideation processes for a more unstructured one. Product architecture fit ensures new initiatives complement
          what we already have rather than create duplication.
        </WppTypography>
        <br />
        <br />
        <WppTypography type="s-strong" tag="span" className={styles.contentText}>
          Benefits:
        </WppTypography>
        <br />
        <WppTypography type="s-body" tag="span" className={styles.contentText}>
          We need to understand whether the goal of the initiative is to generate direct revenue or to enable either
          revenue or cost savings within the overall system. The benefits will affect the business plan and financial
          model.
        </WppTypography>
      </>
    ),
    image: fitAndBenefitsChart,
    icon: fitAndBenefitsIcon,
  },
  {
    id: 2,
    title: 'Complexity and Experience',
    description: (
      <>
        <WppTypography type="s-strong" tag="span" className={styles.contentText}>
          The second analysis is on understanding the the complexity of the build and the experience of the product
          build team.
        </WppTypography>
        <br />
        <br />
        <WppTypography type="s-strong" tag="span" className={styles.contentText}>
          Build Complexity:
        </WppTypography>
        <br />
        <WppTypography type="s-body" tag="span" className={styles.contentText}>
          We define complexity through delivery speed and resource requirements. A "fast" build takes less than 18
          months and is funded by OpEx for a quick delivery. A "far" build takes more than 18 months, needs CapEx funds,
          and will be a more complex build.
        </WppTypography>
        <br />
        <br />
        <WppTypography type="s-strong" tag="span" className={styles.contentText}>
          Team Experience:
        </WppTypography>
        <br />
        <WppTypography type="s-body" tag="span" className={styles.contentText}>
          This is the experience of the build team, not the product team. The build experience includes your build
          partner, so if you are insourcing the build to an experienced WPP team or outsourcing it to a trusted WPP
          partner, their experience counts as yours.
        </WppTypography>
      </>
    ),
    image: complexityAndExperienceChart,
    icon: complexityAndExperience,
  },
  {
    id: 3,
    title: 'Scale and Adoption',
    description: (
      <>
        <WppTypography type="s-strong" tag="span" className={styles.contentText}>
          The third analysis is on understanding the geographic scale of the product and the ease of adoption by the
          target users.
        </WppTypography>
        <br />
        <br />
        <WppTypography type="s-strong" tag="span" className={styles.contentText}>
          Geographic Scale:
        </WppTypography>
        <br />
        <WppTypography type="s-body" tag="span" className={styles.contentText}>
          Simply the expected deployment geography that is getting funded. This includes the overall potential geography
          of the product, but we will focus on the initial deployment expectations.
        </WppTypography>
        <br />
        <br />
        <WppTypography type="s-strong" tag="span" className={styles.contentText}>
          Ease of Adoption:
        </WppTypography>
        <br />
        <WppTypography type="s-body" tag="span" className={styles.contentText}>
          We need to estimate the ease of adoption in order to understand how complex the deployment will be once the
          product is finalised. Higher adoption barriers require more resources to overcome. A global project with high
          barriers is very resource intensive post deployment and this needs to be considered in the business plan and
          in the expected benefit calculations.
        </WppTypography>
      </>
    ),
    image: scaleAndAdoptionChart,
    icon: scaleAndAdoptionIcon,
  },
  {
    id: 4,
    title: 'Sales and CX',
    description: (
      <>
        <WppTypography type="s-strong" tag="span" className={styles.contentText}>
          The fourth analysis is on understanding the team's benefit realisation and monetisation capabilities.
        </WppTypography>
        <br />
        <br />
        <WppTypography type="s-strong" tag="span" className={styles.contentText}>
          CX Capabilities:
        </WppTypography>
        <br />
        <WppTypography type="s-body" tag="span" className={styles.contentText}>
          The team's ability to deliver on a high level Customer Experience post-launch is essential to benefit
          realisation. even if they are internal soft benefits. We tend to fund a lot of great ideas that don't realise
          their benefits due to lack of CX support once deployed.
        </WppTypography>
        <br />
        <br />
        <WppTypography type="s-strong" tag="span" className={styles.contentText}>
          Monetisation Capabilities:
        </WppTypography>
        <br />
        <WppTypography type="s-body" tag="span" className={styles.contentText}>
          The team sales capabilities are a based on ability and size of team. which need to be proportional to the
          challenge.
        </WppTypography>
      </>
    ),
    image: salesAndCxChart,
    icon: salesAndCXIcon,
  },
]

export const analysisCards = [
  {
    id: 1,
    title: 'Fit and Benefits',
    description: 'Does the initiative fit with WPP? What kind of benefits does it enable?',
    link: routesManager.evaluatorTools.fitAndBenefit.getURL(),
    icon: fitAndBenefitsSmallIcon,
  },
  {
    id: 2,
    title: 'Complexity and Experience',
    description: 'Is the experience of the build team proportional to the complexity of the initiative?',
    link: routesManager.evaluatorTools.complexityAndExperience.getURL(),
    icon: complexityAndExperienceSmall,
  },
  {
    id: 3,
    title: 'Scale and Adoption',
    description:
      'What is the potential scale of the initiative and will adoption barriers might get in the way of scaling?',
    link: routesManager.evaluatorTools.scaleAndAdoption.getURL(),
    icon: scaleAndAdoptionSmallIcon,
  },
  {
    id: 4,
    title: 'Sales and CX',
    description: 'Does the initiative have a solid sales and customer experience team and strategy?',
    link: routesManager.evaluatorTools.salesAndCX.getURL(),
    icon: salesAndCXSmallIcon,
  },
]

export const evaluatorRoutes = [
  {
    label: 'Categorisation Matrix',
    path: routesManager.evaluatorTools.landing.getURL(),
  },
  {
    label: 'Analysis',
    path: routesManager.evaluatorTools.analysis.getURL(),
  },
]
