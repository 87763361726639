import {
  WppButton,
  WppInlineMessage,
  WppInput,
  WppTextareaInput,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import React, { FormEvent, useCallback, useState } from 'react'

import { useSendEnquiryApi } from 'api/projects/mutation/useSendEnquiryApi'
import { ContactCard } from 'components/common/contactCard/ContactCard'
import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/contact/ContactPage.module.scss'
import { ContactPageSchema } from 'pages/contact/ContactPageSchema'
import { validationSchemaAdapter } from 'utils/formValidation'

const contacts = [
  {
    name: 'Roy Armale',
    title: 'WPP SVP Product & Platform',
    mail: 'roy.armale@wpp.com',
    image: 'roy',
  },
  {
    name: 'Christian Mouton',
    title: 'Chief Technology Officer',
    mail: 'christian.mouton@wpp.com',
    image: 'christian',
  },
  {
    name: 'Melissa Gay',
    title: 'Senior Director, Global Product Management',
    mail: 'melissa.gay@wpp.com',
    image: 'melissa',
  },
]

export const ContactPage = () => {
  const { osContext } = useOs()
  const { firstname, lastname, agency, email, jobTitle } = osContext.userDetails
  const [form, setForm] = useState({ firstname, lastname, agency, email, jobTitle: jobTitle ?? '', enquiry: '' })
  const [formErrors, setFormErrors] = useState({
    firstname: { status: false, message: '' },
    lastname: { status: false, message: '' },
    agency: { status: false, message: '' },
    email: { status: false, message: '' },
    jobTitle: { status: false, message: '' },
    enquiry: { status: false, message: '' },
  })

  const schema = validationSchemaAdapter(ContactPageSchema)

  const [submitStatus, setSubmitStatus] = useState<boolean | undefined>()

  const { mutateAsync: sendEnquiry } = useSendEnquiryApi()

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      await schema
        .validate(form)
        .then(async () => {
          try {
            await sendEnquiry({
              name: form.firstname,
              surname: form.lastname,
              jobTitle: form.jobTitle ?? '',
              agency: form.agency,
              email: form.email,
              enquiry: form.enquiry,
            })

            setSubmitStatus(true)
          } catch (e) {
            console.error(e)
            setSubmitStatus(false)
          }
        })
        .catch(e => {
          for (const issue of e.inner) {
            setFormErrors(prevState => ({
              ...prevState,
              [issue.path]: { status: true, message: issue.message },
            }))
          }
        })
    },
    [form, schema, sendEnquiry],
  )

  const handleChange = (value: string, key: string) => {
    setForm(prevState => ({
      ...prevState,
      [key]: value,
    }))
    setFormErrors(prevState => ({
      ...prevState,
      [key]: { status: false, message: '' },
    }))
  }

  return (
    <div className={styles.pageContent}>
      <div className={styles.form}>
        <WppTypography className={styles.title} type="4xl-display" tag="h2">
          Contact us
        </WppTypography>

        <WppTypography className={styles.title} type="m-midi" tag="span">
          For any questions or feedback - please contact us, alternatively enter a few details below and we'll get back
          to you.
        </WppTypography>
      </div>
      <Flex gap={120}>
        <div className={styles.form}>
          <form className={styles.marginBottom40} onSubmit={handleSubmit}>
            <Flex gap={20} className={styles.marginBottom20}>
              <WppInput
                name="form_name"
                placeholder="Name"
                maxMessageLength={50}
                labelConfig={{ text: 'Name' }}
                required
                className={styles.width100}
                value={form.firstname}
                onWppChange={e => handleChange(e.target.value, 'firstname')}
                messageType={formErrors.firstname.status ? 'error' : undefined}
                message={formErrors.firstname.message}
              />
              <WppInput
                name="form_surname"
                placeholder="Surname"
                maxMessageLength={50}
                labelConfig={{ text: 'Surname' }}
                required
                className={styles.width100}
                value={form.lastname}
                onWppChange={e => handleChange(e.target.value, 'lastname')}
                messageType={formErrors.lastname.status ? 'error' : undefined}
                message={formErrors.lastname.message}
              />
            </Flex>
            <Flex gap={20} className={styles.marginBottom20}>
              <WppInput
                name="form_title"
                placeholder="Job title"
                maxMessageLength={50}
                labelConfig={{ text: 'Job title' }}
                required
                className={styles.width100}
                value={form.jobTitle ?? undefined}
                onWppChange={e => handleChange(e.target.value, 'jobTitle')}
                messageType={formErrors.jobTitle.status ? 'error' : undefined}
                message={formErrors.jobTitle.message}
              />
              <WppInput
                name="form_agency"
                placeholder="Agency"
                maxMessageLength={50}
                labelConfig={{ text: 'Agency' }}
                required
                className={styles.width100}
                value={form.agency}
                onWppChange={e => handleChange(e.target.value, 'agency')}
                messageType={formErrors.agency.status ? 'error' : undefined}
                message={formErrors.agency.message}
              />
            </Flex>
            <WppInput
              name="form_email"
              placeholder="Email"
              maxMessageLength={50}
              labelConfig={{ text: 'Email' }}
              type="email"
              value={form.email}
              onWppChange={e => handleChange(e.target.value, 'email')}
              required
              className={clsx(styles.marginBottom20, styles.width50)}
              messageType={formErrors.email.status ? 'error' : undefined}
              message={formErrors.email.message}
            />
            <WppTextareaInput
              name="enquiry"
              placeholder="Enquiry"
              charactersLimit={400}
              warningThreshold={401}
              labelConfig={{ text: 'Enquiry' }}
              value={form.enquiry}
              onWppChange={e => handleChange(e.target.value, 'enquiry')}
              required
              className={styles.marginBottom20}
              messageType={formErrors.enquiry.status ? 'error' : undefined}
              message={formErrors.enquiry.message}
            />
            <WppButton variant="primary" size="s" type="submit" className={styles.marginBottom20}>
              Submit
            </WppButton>
            {submitStatus === true && (
              <WppInlineMessage
                size="m"
                message="Your enquiry was successfully submitted."
                type="success"
                showTooltipFrom={50}
              />
            )}
            {submitStatus === false && (
              <WppInlineMessage
                size="m"
                message="Error occurred while submitting your enquiry."
                type="error"
                showTooltipFrom={50}
              />
            )}
          </form>
        </div>
        <div>
          {contacts.map(contact => (
            <div key={contact.name} className={styles.contactCard}>
              <ContactCard name={contact.name} title={contact.title} mail={contact.mail} image={contact.image} />
            </div>
          ))}
        </div>
      </Flex>
    </div>
  )
}
