import { z } from 'zod'

export const ContactPageSchema = z.object({
  firstname: z.string().min(1).max(40),
  lastname: z.string().min(3).max(40),
  agency: z.string().min(3).max(40),
  email: z.string().email().min(3).max(40),
  jobTitle: z.string().min(3).max(40),
  enquiry: z.string().min(3).max(400),
})
