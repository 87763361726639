import { useOs } from '@wpp-open/react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import styles from 'app/App.module.scss'
import { Header } from 'app/header/Header'
import { ContactPage } from 'pages/contact/ContactPage'
import { DashboardPage } from 'pages/dashboard/DashboardPage'
import { EvaluatorToolsPage } from 'pages/evaluator/EvaluatorToolsPage'
import { LiveplanPage } from 'pages/liveplan/LiveplanPage'
import { StepPage } from 'pages/step/StepPage'
import { StepsOverviewPage } from 'pages/steps/StepsOverviewPage'
import { routesManager } from 'utils/routesManager'

export function App() {
  /**
   * This hook is provided by the utility package for more convenient work with OS context
   * @return osContext
   * - is a subscribe function that returns you the main context object with MicroAppContext data
   * @return osApi
   * - methods to fire actions on the parent OS level
   *
   * Read more about it in the README file
   */
  const { osContext } = useOs()

  return (
    <BrowserRouter basename={osContext.baseUrl}>
      <div className={styles.container}>
        <Header />
        <div>
          <Routes>
            <Route path={routesManager.dashboard.root.pattern} element={<DashboardPage />} />
            <Route path={routesManager.steps.root.pattern} element={<StepsOverviewPage />} />
            <Route path={routesManager.steps.step.pattern} element={<StepPage />} />
            <Route path={routesManager.liveplan.root.pattern} element={<LiveplanPage />} />
            <Route path={routesManager.evaluatorTools.root.pattern} element={<EvaluatorToolsPage />} />
            <Route path={routesManager.contact.root.pattern} element={<ContactPage />} />
            <Route index element={<Navigate to={routesManager.dashboard.root.pattern} />} />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  )
}
