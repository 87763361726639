import { NavigationState } from '@platform-ui-kit/components-library'
import { WppTopbar, WppTypography } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import styles from 'app/header/Header.module.scss'

const baseNavigation: NavigationState[] = [
  {
    label: 'WPP Funding Process',
    value: 'process',
    children: [
      { label: 'Dashboard', value: 'dashboard' },
      {
        label: 'Steps',
        value: 'steps',
        children: [
          { label: 'Steps overview', value: 'steps' },
          { label: 'Step 1', value: 'steps/1' },
          { label: 'Step 2', value: 'steps/2' },
          { label: 'Step 3', value: 'steps/3' },
          { label: 'Step 4', value: 'steps/4' },
          { label: 'Step 5', value: 'steps/5' },
        ],
      },
      { label: 'LivePlan', value: 'liveplan' },
      { label: 'Evaluator tools', value: 'evaluator-tools' },
      { label: 'Contact', value: 'contact' },
    ],
  },
]

export const Header = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { osContext } = useOs()

  const navigation = useMemo(
    () =>
      baseNavigation.map(navItem => ({
        ...navItem,
        // Displayed links will have correct full url
        path: `/${osContext.baseUrl}/${navItem.value}`,
      })),
    [osContext.baseUrl],
  )
  const topbarValue = navigation
    .map(navItem => navItem.value)
    .find(navItem => location.pathname.startsWith(`/${navItem}`))

  return (
    <WppTopbar
      className={styles.wppTopBar}
      value={topbarValue}
      navigation={navigation}
      onWppChange={e => navigate(`/${e.detail.value}`)}
    >
      <div slot="app" className={styles.wppTopBarLogo}>
        <WppTypography className={styles.wppTopBarTitle} type="m-strong" tag="h3">
          DevHub
        </WppTypography>
      </div>
    </WppTopbar>
  )
}
