// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".E3DPg{--page-left-right-padding: 38px;padding:0 var(--page-left-right-padding)}._jogS{--stepper-translate-position: 0 !important}.Umbs2{display:block;margin-top:56px;margin-bottom:32px}.f2Ede{width:100%}.q7Zbe::part(card){min-height:350px;padding-right:0;padding-left:0;box-shadow:none}.qHREt{padding:30px 250px 30px 20px}.Z6c1C{width:350px;padding-left:48px}.rmHYI{margin-bottom:7px}.ZBZ5Q{padding-bottom:60px}._3iJf{display:block;color:var(--wpp-grey-color-800)}", "",{"version":3,"sources":["webpack://./src/pages/step/StepPage.module.scss"],"names":[],"mappings":"AAAA,OACE,+BAAA,CAEA,wCAAA,CAGF,OACE,0CAAA,CAGF,OACE,aAAA,CACA,eAAA,CACA,kBAAA,CAGF,OACE,UAAA,CAIA,mBACE,gBAAA,CACA,eAAA,CACA,cAAA,CACA,eAAA,CAIJ,OACE,4BAAA,CAGF,OACE,WAAA,CACA,iBAAA,CAGF,OACE,iBAAA,CAGF,OACE,mBAAA,CAGF,OACE,aAAA,CACA,+BAAA","sourcesContent":[".pageContent {\n  --page-left-right-padding: 38px;\n\n  padding: 0 var(--page-left-right-padding);\n}\n\n.stepper {\n  --stepper-translate-position: 0 !important;\n}\n\n.title {\n  display: block;\n  margin-top: 56px;\n  margin-bottom: 32px;\n}\n\n.content {\n  width: 100%;\n}\n\n.tabs {\n  &::part(card) {\n    min-height: 350px;\n    padding-right: 0;\n    padding-left: 0;\n    box-shadow: none;\n  }\n}\n\n.tabContent {\n  padding: 30px 250px 30px 20px;\n}\n\n.rightNavigation {\n  width: 350px;\n  padding-left: 48px;\n}\n\n.margin7 {\n  margin-bottom: 7px;\n}\n\n.paddingBottom {\n  padding-bottom: 60px;\n}\n\n.cardDescription {\n  display: block;\n  color: var(--wpp-grey-color-800);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContent": "E3DPg",
	"stepper": "_jogS",
	"title": "Umbs2",
	"content": "f2Ede",
	"tabs": "q7Zbe",
	"tabContent": "qHREt",
	"rightNavigation": "Z6c1C",
	"margin7": "rmHYI",
	"paddingBottom": "ZBZ5Q",
	"cardDescription": "_3iJf"
};
export default ___CSS_LOADER_EXPORT___;
